import React, { useState } from 'react';

import { db } from "../firebaseConfig"
import { collection, addDoc, getDoc, doc, setDoc, getCountFromServer, count, getFirestore } from "@firebase/firestore";


function AdminSivu() {

    const [password, setPassword] = useState("");
    const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
    const [type, setType] = useState(0);
    const [subject, setSubject] = useState("Biologia");
    const [topic, setTopic] = useState("Biologia1");


    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === "siyar") {
            setPasswordIsCorrect(true);
        }
        setPassword("");
    }

    async function SendToDatabase(data) {
        console.log("Sending to database. DATA: ", data);

        try {

            const firestore = getFirestore();
            const userCollectionReference = collection(firestore, "Cards", subject, topic);
            const userCollectionSnapshot = await getCountFromServer(userCollectionReference);

            const CountOfCardsInDB = userCollectionSnapshot.data().count;
            const CardName = String("Card" + CountOfCardsInDB)

            try {
                await setDoc(doc(db, "Cards", subject, topic, "Card3"), data, { merge: true });
                console.log("Document Added Successfully");
            }
            catch (error) {
                console.log(error);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const sendType1 = () => {
        var type = 1;
        var questionText = "Mitä on biologia?1";
        var NumberOfAnswers = 4;
        var answerOptions = [
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
        ];
        var hint = "asdlkjsd";

        var data = {
            type: type,
            questionText: questionText,
            NumberOfAnswers: NumberOfAnswers,
            answerOptions: answerOptions,
            hint: hint
        }

        console.log(data);
        SendToDatabase(data);

    }


    const sendType2 = () => {

        var type = 2;
        var questionText = "Onko punasoluissa rautaa?";
        var questionMedia = "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1";
        var isItTrue = true;
        var hint = "sdasd";

        var data = {
            type: type,
            questionText: questionText,
            questionMedia: questionMedia,
            isItTrue: isItTrue,
            hint: hint
        }

        console.log(data);
        SendToDatabase(data);

    }

    const sendType3 = () => {

        var type = 3;
        var questionText = "Mikä on eläimen nimi, joka nukkuu talviunta?";
        var questionMedia = "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1";
        var correctAnswer = "Karhu";
        var hint = "Karhu nukkuu talviunta";

        var data = {
            type: type,
            questionText: questionText,
            questionMedia: questionMedia,
            correctAnswer: correctAnswer,
            hint: hint
        }

        console.log(data);
        SendToDatabase(data);
    }

    // KESKENERÄINEN

    const sendType4 = () => {
        var hint = "asdlkjsd";
        return null
    }

    const sendType5 = () => {
        var hint = "asdlkjsd";

        return null
    }

    const sendType6 = () => {
        var type = 6;
        var questionText = "Mitä on biologia?1";
        var NumberOfAnswers = 4;
        var answerOptions = [
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: true },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: true },
            { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
        ];
        var hint = "asdlkjsd";

        var data = {
            type: type,
            questionText: questionText,
            NumberOfAnswers: NumberOfAnswers,
            answerOptions: answerOptions,
            hint: hint
        }

        console.log(data);
        SendToDatabase(data);
    }

    const sendType7 = () => {
        var type = 7;
        var flashcardFront = "Mitä on biologia?2-1";
        var flashcardFrontMedia = "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1";
        var flashcardBack = "Tieteellinen tiede, joka tutkii eläviä olioita";
        var flashcardBackMedia = "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1";
        var hint = "asdasd";

        var data = {
            type: type,
            flashcardFront: flashcardFront,
            flashcardFrontMedia: flashcardFrontMedia,
            flashcardBack: flashcardBack,
            flashcardBackMedia: flashcardBackMedia,
            hint: hint
        }

        console.log(data);
        SendToDatabase(data);
    }

    const sendType8 = () => {
        var hint = "asdlkjsd";
        return null
    }

    const sendType9 = () => {
        var hint = "asdlkjsd";
        return null
    }

    const sendType10 = () => {
        var hint = "asdlkjsd";
        return null
    }

    const sendType11 = () => {
        var hint = "asdlkjsd";
        return null
    }


    return (
        <>

            {
                !passwordIsCorrect ?
                    <>
                        <div>AdminSivu</div>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleSubmit}>Submit</button>
                    </>

                    :
                    <>

                        <button onClick={() => setTopic("Biologia1")}> Biologia1 </button>
                        <button onClick={() => setTopic("Biologia2")}> Biologia2 </button>
                        <button onClick={() => setTopic("Biologia3")}> Biologia3 </button>
                        <button onClick={() => setTopic("Biologia4")}> Biologia4 </button>
                        <button onClick={() => setTopic("Biologia5")}> Biologia5 </button>

                        <p>Current Selected Biologia Topic: {topic}</p>

                        <br />

                        <p>Select Type: </p>

                        <button onClick={() => setType(1)}>Type 1</button>
                        <button onClick={() => setType(2)}>Type 2</button>
                        <button onClick={() => setType(3)}>Type 3</button>
                        <button onClick={() => setType(4)}>Type 4</button>
                        <button onClick={() => setType(5)}>Type 5</button>
                        <button onClick={() => setType(6)}>Type 6</button>
                        <button onClick={() => setType(7)}>Type 7</button>
                        <button onClick={() => setType(8)}>Type 8</button>
                        <button onClick={() => setType(9)}>Type 9</button>
                        <button onClick={() => setType(10)}>Type 10</button>
                        <button onClick={() => setType(11)}>Type 11</button>


                        {
                            type === 1 ? (
                                <>
                                    <div>type 1</div>
                                    <button onClick={sendType1}>Send</button>
                                </>
                            )
                                : type === 2 ? (
                                    <>
                                        <div>type 2</div>
                                        <button onClick={sendType2}> Send </button>
                                    </>
                                )
                                    : type === 3 ? (
                                        <>
                                            <div>type 3</div>
                                            <button onClick={sendType3}> Send </button>
                                        </>
                                    )
                                        : type === 4 ? (
                                            <>
                                                <div>type 4</div>
                                                <button onClick={sendType4}> Send </button>
                                            </>
                                        )
                                            : type === 5 ? (
                                                <>
                                                    <div>type 5</div>
                                                    <button onClick={sendType5}> Send </button>
                                                </>
                                            )
                                                : type === 6 ? (
                                                    <>
                                                        <div>type 6</div>
                                                        <button onClick={sendType6}> Send </button>
                                                    </>
                                                )
                                                    : type === 7 ? (
                                                        <>
                                                            <div>type 7</div>
                                                            <button onClick={sendType7}> Send </button>
                                                        </>
                                                    )
                                                        : type === 8 ? (
                                                            <>
                                                                <div>type 8</div>
                                                                <button onClick={sendType8}> Send </button>
                                                            </>
                                                        )
                                                            : type === 9 ? (
                                                                <>
                                                                    <div>type 9</div>
                                                                    <button onClick={sendType9}> Send </button>
                                                                </>
                                                            )
                                                                : type === 10 ? (
                                                                    <>
                                                                        <div>type 10</div>
                                                                        <button onClick={sendType10}> Send </button>
                                                                    </>
                                                                )
                                                                    : type === 11 ? (
                                                                        <>
                                                                            <div>type 11</div>
                                                                            <button onClick={sendType11}> Send </button>
                                                                        </>
                                                                    )
                                                                        : (
                                                                            <div>ERROR</div>
                                                                        )
                        }

                    </>
            }

        </>
    )
}

export default AdminSivu