// Type 1: Multiple choice with text/image/video x + Type 6: Multiple choice: Click On Right Answers (Multiple) x -> 1 <-
// Type 2: True or false with text/image/video x -> 1 <-
// Type 3: Fill in the blank with text/image/video x + Type 9: Cloze (Fill the Blank) x -> 1 <-
// Type 4: Drag and drop with text (BG4 Style) x -> 1 <-
// Type 7: Flashcards x -> 1 <-
// Type 8: Matching (No Need?)
// Type 10: Essee (Text)



export const GuestBiologia = {

    "BG1": {

        1: {
            type: 1,
            questionText: "Iho pigmentin nimi?",
            // Add questionMedia later
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Meloniini", correctOption: false },
                { answerText: "Melianiini", correctOption: false },
                { answerText: "Melaniini", correctOption: true },
                { answerText: "Ihossa oleva pigmentti", correctOption: false },
            ],
            AlkuDescription: 'BG1 \n',
        },

        2: {
            type: 7,
            flashcardFront: "Määritä tulokaslaji",
            flashcardBack: "Tulokaslajit ovat lajeja, jotka ovat melko äskettäin siirtyneet uusille alueille itse levittäytyen",
            /*  flashcardFrontMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
             flashcardBackMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
             */
        },

        3: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
            hint: "On 😢"
        },

        4: {
            type: 3,
            questionText: "Suurekosysteemi eli?",
            /* questionMedia: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUMW4oAHh7l681HOsalP4E2C-c_1C1f8JMQw&usqp=CAU",
             */correctAnswer: "Biomi",
            hint: "Biomi on suurekosysteemi",
        },

        5: {
            type: 1,
            questionText: "Termi: Ihminen ymmärtää luonnon merkityksen kaikkiin?",
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Ekososiaallinen sivistys", correctOption: true },
                { answerText: "Luontographia", correctOption: false },
                { answerText: "Biososiaallinen ymmärrys", correctOption: false },
                { answerText: "Biokemia", correctOption: false },
            ],
        },

        6: {
            type: 7,
            flashcardFront: "Miksi dinosaurukset gg?",
            flashcardBack: "asteroidi --> pölypilvi --> ei auringon säteilyä",
        },

        7: {
            type: 2,
            questionText: "Virus kuoren nimi on kapseli",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
            hint: "Kapsidi 😢"
        },

        8: {
            type: 7,
            flashcardFront: "Minne haiman ja sappirakon entsyymit valuvat?",
            flashcardBack: "Ohutusuolen alkuun eli pohjukkaissuoleen ('duodenum')",
        },

        9: {
            type: 2,
            questionText: "Kilpirauhanen tuottaa parathormonia",
            isItTrue: false,
        },

        10: {
            type: 1,
            questionText: "Valitse kaikki oikeat vaihtoehdot: Haiman tuottamat entsyymit",
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Amylaasi", correctOption: true },
                { answerText: "Lipaasi", correctOption: true },
                { answerText: "Trypsiini", correctOption: true },
                { answerText: "Maltaasi", correctOption: false },
            ],
        },

        11: {
            type: 7,
            flashcardFront: "Sädehoidolla tuhotaan pääsääntöisesti mitä?",
            flashcardBack: "Jakautumisvaiheessa olevia soluja",
        },

        12: {
            type: 3,
            questionText: "Laskimo latinaksi?",
            /* questionMedia: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUMW4oAHh7l681HOsalP4E2C-c_1C1f8JMQw&usqp=CAU",
             */correctAnswer: "Vena",
            hint: "Lyhenne v.",
        },

        13: {
            type: 2,
            questionText: "Nisäkkäät elivät noin 200 miljoonaa vuotta sitten",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        14: {
            type: 2,
            questionText: "Suvuttomasti lisääntyvillä on hyvä regeneraatiokyky",
            isItTrue: true,
        },
        15: {
            type: 4,
            questionText: "",
            questionMedia: "https://peda.net/nivala/nivalan-lukio/oppiaineet22/psykologia/psykologia-arkisto/ptki/ikhp/hrjt/hermosolu-jpg:file/photo/c4dabc70bf929b9e5355888b5359b234299de3f8/hermosolu.jpg",
            NumberOfAnswers: 4,
            svgCoordinates: [
                { answerText: "Myeliinituppi", x: "43%", y: "20%", width: "15%", height: "10%", id: "1" },
                { answerText: "Synapsi", x: "67%", y: "10%", width: "15%", height: "8%", id: "2" },
                { answerText: "Dendriitti", x: "20%", y: "7%", width: "15%", height: "8%", id: "3" },
                { answerText: "Tuma", x: "16%", y: "70%", width: "15%", height: "8%", id: "4" },
            ],

        },
        16: {
            type: 4,
            questionText: "",
            questionMedia: "https://www.aiheenasyopa.fi/sites/g/files/vrxlpx30261/files/2020-08/Tietoa-Kilpirauhasesta.png",
            NumberOfAnswers: 3,
            svgCoordinates: [
                { answerText: "Kilpirauhanen", x: "75%", y: "38%", width: "15%", height: "6%", id: "1" },
                { answerText: "Kilpirusto", x: "75%", y: "45%", width: "20%", height: "6%", id: "2" },
                { answerText: "Henkitorvi", x: "75%", y: "52%", width: "15%", height: "6%", id: "3" },
            ],

        },
        17: {
            type: 4,
            questionText: "",
            questionMedia: "https://media.istockphoto.com/id/1482221070/fi/vektori/limbinen-j%C3%A4rjestelm%C3%A4-ihmisaivojen-poikkileikkaus.jpg?s=612x612&w=0&k=20&c=6PeF_Qfsc5LBV87qhx7LLi5WjmssW9Oa9rcaEoqdcg8=",
            NumberOfAnswers: 3,
            svgCoordinates: [
                { answerText: "Talamus", x: "6%", y: "30%", width: "15%", height: "5%", id: "1" },
                { answerText: "Hypotalamus", x: "76%", y: "62%", width: "15%", height: "5%", id: "2" },
                { answerText: "Hippokampus", x: "58%", y: "84%", width: "15%", height: "5%", id: "3" },
            ],

        },

    },
    "BG2": {
        1: {
            type: 1,
            questionText: "Mitä on biologia?15",
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
            AlkuDescription: 'Tässä tulet oppimaan BG1 \n voluptas, quod, \n quia, voluptates quae voluptatibus \n quibusdam Lorem ipsum dolor sit amet consectetur adipisicing elit. \n Voluptas, quod, quia, voluptates quae voluptatibus quibusdam',
        },

        2: {
            type: 7,
            flashcardFront: "Mitä on biologia?2-1",
            flashcardFrontMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            flashcardBack: "Tieteellinen tiede, joka tutkii eläviä olioita",
            flashcardBackMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
        },

        3: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        4: {
            type: 3,
            questionText: "Mikä on eläimen nimi, joka nukkuu talviunta?",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            correctAnswer: "Karhu",
            hint: "Karhu nukkuu talviunta",
        },

        5: {
            type: 1,
            questionText: "5 Kysymys",
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
        },

        6: {
            type: 7,
            flashcardFront: "Mitä 6 ",
            flashcardFrontMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            flashcardBack: "Tieteellinen tiede, joka tutkii eläviä olioita",
            flashcardBackMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
        },

        7: {
            type: 2,
            questionText: "Punasolut ovat väriltään ___ ja ovat ?",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        8: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?8",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        9: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?9",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        10: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?10",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        11: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?11",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        12: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?12",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        13: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?13",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },

        14: {
            type: 2,
            questionText: "Onko punasoluissa rautaa?14",
            questionMedia: "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1",
            isItTrue: true,
        },
        15: {
            type: 6,
            questionText: "Mitä on biologia?1",
            NumberOfAnswers: 4,
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
        },

    },
    "BG3": {
        1: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?333",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
            AlkuDescription: "Tässä tulet oppimaan BG3333"

        },
        2: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?33333333333",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: true },
            ],
        },

    },
    "BG4": {
        1: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?7",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
            AlkuDescription: "Tässä tulet oppimaan BG4444"

        },
        2: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?8",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: true },
            ],
        },

    },
    "BG5": {
        1: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?9",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
            ],
            AlkuDescription: "Tässä tulet oppimaan BG55555"

        },
        2: {
            type: 1,
            NumberOfAnswers: 4,
            questionText: "Mitä on biologia?10",
            answerOptions: [
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
                { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: true },
            ],
        },

    }

}