import React, { useEffect, useRef, useState } from 'react';

import {
    Button,
    Box,
    Image,
    Text,
    FormControl,
    Input,
    FormLabel,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

function FeedbackButton({ QuestionsNumber, setFeedbackButtonOpen, setFeedbackButtonClose, FeedbackButtonClose, FeedbackButtonOpen }) {

    const { isOpen, onClose, onOpen } = useDisclosure();
    const initialRef = useRef(null);
    const [value, setValue] = useState("");

    const handleChange = (event) => setValue(event.target.value);

    return (
        <>
            <Modal isOpen={FeedbackButtonOpen} onClose={() => {setFeedbackButtonOpen(!FeedbackButtonOpen)}}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color="#276749">
                        Lähetä palaute tehtävästä: {QuestionsNumber ? QuestionsNumber : "Havaittu Onglema" }
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Sähköposti</FormLabel>
                            <Input ref={initialRef} placeholder='example@mail.com' />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Viestikenttä: {value}</FormLabel>
                            <Input value={value} onChange={handleChange} placeholder='Viesti' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='green' mr={3} send >
                            Lähetä Viesti
                        </Button>
                        <Button mr={3} onClick={() => { setFeedbackButtonClose(!FeedbackButtonClose); setFeedbackButtonOpen(!FeedbackButtonOpen) }}>
                            Sulje
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FeedbackButton