import React, { useContext } from 'react';

import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Text,
    Stack,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';
import { AiFillCheckCircle } from "react-icons/ai";


import { GlobalContext } from '../components/GlobalContext/GlobalContext';


function ProfilePage() {


    const { isSignedIn, setIsSignedIn, isPremium, setIsPremium, user, setUser, signOutContext } = useContext(GlobalContext);

    return (
        <>
            <Center py={6}>
                <Box
                    maxW={'270px'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <Image
                        h={'120px'}
                        w={'full'}
                        src={
                            'https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
                        }
                        objectFit={'cover'}
                    />
                    <Flex justify={'center'} mt={-12}>
                        <Avatar
                            size={'xl'}
                            src={
                                user.photoURL
                            }
                            alt={'Author'}
                            css={{
                                border: '2px solid white',
                            }}
                        />
                    </Flex>

                    <Box p={6}>
                        <Stack spacing={0} align={'center'} mb={5}>
                            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                                {user.displayName}
                            </Heading>
                            <Text color={'gray.500'}>{user.email}</Text>
                        </Stack>

                        <Stack direction={'row'} justify={'center'} spacing={6}>
                            <Stack spacing={0} align={'center'}>
                                <Text fontWeight={600}>{isPremium ? <AiFillCheckCircle color={"green"} /> : <CloseIcon color={"red"} />}</Text>
                                <Text fontSize={'sm'} color={'gray.500'}>
                                    Premium
                                </Text>
                            </Stack>
                        </Stack>

                        <Button
                            w={'full'}
                            mt={8}
                            bg={useColorModeValue('#151f21', 'gray.900')}
                            color={'white'}
                            rounded={'md'}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                            onClick={() => signOutContext()}
                        >
                            Log out
                        </Button>
                    </Box>
                </Box>
            </Center>

        </>

    )
}

export default ProfilePage