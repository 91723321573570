import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCGtoeJQlr-2LTPINitWOithFlz_wq3BUk",
  authDomain: "harkama-d460b.firebaseapp.com",
  databaseURL: "https://harkama-d460b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "harkama-d460b",
  storageBucket: "harkama-d460b.appspot.com",
  messagingSenderId: "794293143278",
  appId: "1:794293143278:web:2ecbe2dfb6ec3b90d3d5ff",
  measurementId: "G-2PPWG428SP"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();