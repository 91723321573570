import React, { useContext, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import "../styles/NavbarStyle.css"

import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './GlobalContext/GlobalContext';

import {
  Box,
  Button,
  Flex,
  Avatar,
  HStack,
  Link,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import { FiSettings } from "react-icons/fi";
import { AiFillCheckCircle } from "react-icons/ai";




const NavLink = ({ children, isPremium, setGameIsOn, setSubject, setTopic, navigate }) => (
  <Link
    as={RouterLink}
    px={2}
    py={1}
    to={'/' + children}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}
    onClick={() => {
      if (children === "" && !isPremium) {
        setGameIsOn(false); setSubject(false); setTopic(false)
      }
    }}
  >
    {children === '' ? 'Home' : children}
  </Link>
);

export default function NavBar() {

  const { isSignedIn, isPremium, setClearLocalStorageCounterForReRender, clearLocalStorageCounterForReRender, setGameIsOn, setSubject, setTopic, user } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenSettingsModal, onOpen: onOpenSettingsModal, onClose: onClosesSettingsModal } = useDisclosure();

  const Links = ['', 'About', isSignedIn ? 'Profile' : 'Login'];

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Image src="https://media.istockphoto.com/id/1299800893/vector/letter-funny-character-with-cute-face.jpg?s=612x612&w=0&k=20&c=Lo6ZEqhtPmRa074qyWGBHSBg6QrdZuznqDQwZAD7-As=" boxSize="50px" />
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link} {...{isPremium, setGameIsOn, setSubject, setTopic, navigate}}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          {
            isSignedIn &&
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size={'sm'}
                    src={
                      user.photoURL
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => navigate("/Profile")} >{user.displayName}</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={onOpenSettingsModal}>Asetukset ‎ <FiSettings /> </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          }
        </Flex>

        {
          isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </Stack>
            </Box>
          ) : null
        }
      </Box>


      <Modal isOpen={isOpenSettingsModal} onClose={onClosesSettingsModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Asetukset
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button onClick={() => { localStorage.clear(); setClearLocalStorageCounterForReRender(clearLocalStorageCounterForReRender + 1) }}> Tyhjennä Localstorage</Button>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClosesSettingsModal} rightIcon={<CloseIcon />}>
              Sulje
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}