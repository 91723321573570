import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { db, auth, provider } from "../firebaseConfig";
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, addDoc, setDoc, doc, getDoc } from "@firebase/firestore";


import { GlobalContext } from '../components/GlobalContext/GlobalContext';




function LoginPage() {

  const navigate = useNavigate();


  const { setIsSignedIn, setIsPremium, setUser, user } = useContext(GlobalContext);



  const SignIn = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {


        console.log(result.user);



        if (parseInt(result.user.metadata.lastLoginAt) - parseInt(result.user.metadata.createdAt) < 8000) {
          console.log("Ensimmäistä kertaa kirjauduttu sisään")


          // Create new user in database
          try {
            localStorage.removeItem("user");

            setDoc(doc(db, "Users", result.user.uid), {
              uid: result.user.uid,
              displayName: result.user.displayName,
              email: result.user.email,
              photoURL: result.user.photoURL,
              createdAt: result.user.metadata.createdAt,
              lastLoginAt: result.user.metadata.lastLoginAt,
              CodecLite: true,
              Biologia1QuestionArray: {},
              Biologia2QuestionArray: {},
              Biologia3QuestionArray: {},
              Biologia4QuestionArray: {},
              Biologia5QuestionArray: {},
              Biologia1TotalScore: 0,
              Biologia2TotalScore: 0,
              Biologia3TotalScore: 0,
              Biologia4TotalScore: 0,
              Biologia5TotalScore: 0,
            });


            setUser({
              uid: result.user.uid,
              displayName: result.user.displayName,
              email: result.user.email,
              photoURL: result.user.photoURL,
              createdAt: result.user.metadata.createdAt,
              lastLoginAt: result.user.metadata.lastLoginAt,
              CodecLite: true,
              Biologia1QuestionArray: {},
              Biologia2QuestionArray: {},
              Biologia3QuestionArray: {},
              Biologia4QuestionArray: {},
              Biologia5QuestionArray: {},
              Biologia1TotalScore: 0,
              Biologia2TotalScore: 0,
              Biologia3TotalScore: 0,
              Biologia4TotalScore: 0,
              Biologia5TotalScore: 0,
            });

            localStorage.setItem("user", JSON.stringify({
              uid: result.user.uid,
              displayName: result.user.displayName,
              email: result.user.email,
              photoURL: result.user.photoURL,
              createdAt: result.user.metadata.createdAt,
              lastLoginAt: result.user.metadata.lastLoginAt,
              CodecLite: true,
              Biologia1TotalScore: 0,
              Biologia2TotalScore: 0,
              Biologia3TotalScore: 0,
              Biologia4TotalScore: 0,
              Biologia5TotalScore: 0,
            }));

            setIsPremium(true);

          } catch (error) {
            console.log(error);

          }

        } else {
          console.log("Kirjauduttu sisään jo aiemmin")

          try {

            const docRef = doc(db, "Users", result.user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

              setUser({
                uid: docSnap.data().uid,
                displayName: docSnap.data().displayName,
                email: docSnap.data().email,
                photoURL: docSnap.data().photoURL,
                createdAt: docSnap.data().createdAt,
                lastLoginAt: docSnap.data().lastLoginAt,
                CodecLite: docSnap.data().CodecLite,
                Biologia1QuestionArray: docSnap.data().Biologia1QuestionArray,
                Biologia2QuestionArray: docSnap.data().Biologia2QuestionArray,
                Biologia3QuestionArray: docSnap.data().Biologia3QuestionArray,
                Biologia4QuestionArray: docSnap.data().Biologia4QuestionArray,
                Biologia5QuestionArray: docSnap.data().Biologia5QuestionArray,
                Biologia1TotalScore: docSnap.data().Biologia1TotalScore,
                Biologia2TotalScore: docSnap.data().Biologia2TotalScore,
                Biologia3TotalScore: docSnap.data().Biologia3TotalScore,
                Biologia4TotalScore: docSnap.data().Biologia4TotalScore,
                Biologia5TotalScore: docSnap.data().Biologia5TotalScore,

              });



              localStorage.removeItem("user");


              localStorage.setItem("user", JSON.stringify({
                uid: docSnap.data().uid,
                displayName: docSnap.data().displayName,
                email: docSnap.data().email,
                photoURL: docSnap.data().photoURL,
                createdAt: docSnap.data().createdAt,
                lastLoginAt: docSnap.data().lastLoginAt,
                CodecLite: docSnap.data().CodecLite,
                Biologia1TotalScore: docSnap.data().Biologia1TotalScore,
                Biologia2TotalScore: docSnap.data().Biologia2TotalScore,
                Biologia3TotalScore: docSnap.data().Biologia3TotalScore,
                Biologia4TotalScore: docSnap.data().Biologia4TotalScore,
                Biologia5TotalScore: docSnap.data().Biologia5TotalScore,
              }));



              if (docSnap.data().CodecLite === true) {
                setIsPremium(true);
                console.log("User has premium account");
              }
              else {
                setIsPremium(false);
                console.log("User does not have premium account");
              }




            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          } catch (error) {
            console.log(error);
          }


          // Update lastLoginAt

          /*           try {
                      setDoc(doc(db, "Users", result.user.uid), {
                        lastLoginAt: result.user.metadata.lastLoginAt,
                      }, { merge: true });
                    } catch (error) {
                      console.log(error);
                    } */

        }

        setIsSignedIn(true);

        navigate("/profile");


      }).catch((error) => {
        console.log(error);
      });

  }



  return (
    <>

      <div>LoginPage</div>
      <button onClick={() => { SignIn() }}> Google Login </button>

    </>
  )
}

export default LoginPage