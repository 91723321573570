import React from 'react'
import SubjectTopicDeckBox from "../components/SubjectTopicDeckBox";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Text,
  ButtonGroup,
} from '@chakra-ui/react'


import { MdArrowBackIosNew } from "react-icons/md";



function BiologiaPage() {

  const navigate = useNavigate();

  return (
    <>

      <Button marginTop={"0.5%"} marginBottom={"0.5%"} colorScheme='blue' leftIcon={<MdArrowBackIosNew />} onClick={() => navigate("/")}>Takaisin</Button>

      <SubjectTopicDeckBox />

    </>
  )
}

export default BiologiaPage