import React, { useContext } from 'react';

import { GlobalContext } from '../components/GlobalContext/GlobalContext';

import {
  Button,
  Text,
  ButtonGroup,
  Card,
  CardBody,
  Box,
  Badge,
  Center,
  Image,
  Stack,
} from '@chakra-ui/react';
import { FaDna } from "react-icons/fa";
import { BiAtom } from "react-icons/bi";
import { SlChemistry } from "react-icons/sl";

import { useNavigate } from 'react-router-dom'


// Database Reference
import { db } from '../firebaseConfig';
import { collection, addDoc } from "@firebase/firestore";

import SubjectDeckBox from '../components/SubjectDeckBox';



function HomePagePremium() {

  const navigate = useNavigate();
  const { user } = useContext(GlobalContext);

  return (
    <>
      {/* <button onClick={() => { console.log(user) }}> Current user </button> */}

      <Center paddingTop={"1%"}>
        <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
          <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
            <Stack direction='row' spacing={4} align='center' position={"relative"}>
              <Box
                mt='1'
                fontWeight='bold'
                as='h4'
                lineHeight='tight'
                noOfLines={1}
                fontSize={"xx-large"}
                marginBottom={2}
              >
                Biologia
              </Box>
              <FaDna color='green' size={30} style={{ marginLeft: "15px" }} />

              <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} onClick={() => navigate("/Biologia")} >Valitse</Button>
            </Stack>
          </Box>
        </Box>
      </Center>


      <Center paddingTop={"1%"}>
        <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
          <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
            <Stack direction='row' spacing={4} align='center' position={"relative"}>
              <Box
                mt='1'
                fontWeight='bold'
                as='h4'
                lineHeight='tight'
                noOfLines={1}
                fontSize={"xx-large"}
                marginBottom={2}
              >
                Fysiikka
              </Box>
              <BiAtom color='lightblue' size={30} style={{ marginLeft: "15px" }} />
              <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} isDisabled={true}>Tulossa Pian!</Button>
            </Stack>
          </Box>
        </Box>
      </Center>



      <Center paddingTop={"1%"}>
        <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
          <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
            <Stack direction='row' spacing={4} align='center' position={"relative"}>
              <Box
                mt='1'
                fontWeight='bold'
                as='h4'
                lineHeight='tight'
                noOfLines={1}
                fontSize={"xx-large"}
                marginBottom={2}
              >
                Kemia
              </Box>
              <SlChemistry color='orange' size={30} style={{ marginLeft: "15px" }} />
              <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} isDisabled={true}>Tulossa Pian!</Button>
            </Stack>
          </Box>
        </Box>
      </Center>


    </>
  )
}


export default HomePagePremium