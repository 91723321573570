import React, { useState, useEffect } from 'react';
import '../styles/SubjectTopicBox.css';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    Text,
    ButtonGroup,
    Card,
    CardBody,
    Box,
    Badge,
    Center,
    Image,
    Stack
} from '@chakra-ui/react';

// Database Reference
import { db } from '../firebaseConfig';
import { collection, addDoc, getDoc, doc, setDoc, FieldValue, updateDoc, increment } from "@firebase/firestore";
import { getAuth } from 'firebase/auth';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import ProgressProvider from './ProgressProvider';

function SubjectTopicDeckBox({ topic }) {

    const navigate = useNavigate();

    const [TotalScores, setTotalScores] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
            await getDoc(doc(db, "Users", "oHOmqG0IZMTQdHoLrTbB1MhYrLi2")).then((result) => {
                console.log(result.data())
                setTotalScores(result.data());
            })
        };
        fetchData();
    }, []);


    const CircularProgBar = ({ topicText }) => {

        // Number((userRef[topicText] / 200 * 100).toFixed(2)
        console.log(topicText, " TotalScores: ", TotalScores && TotalScores[topicText])
        let numberOfAllQuestionsPerTopic = 100

        return (
            <div style={{ width: 100, height: 100 }}>
                <ProgressProvider valueStart={0} valueEnd={(TotalScores ? TotalScores[topicText] : 10) *100 / (numberOfAllQuestionsPerTopic * 5)}>
                    {value => <CircularProgressbar value={value} text={`${value}%`} />}
                </ProgressProvider>
            </div>
        )
    }


    return (
        <>

            <Center className='subject-topic-box-center1'>
                <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                    <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                        <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Alku</Text>
                        {/* Oikea reuna: Ilmais kortteja: (16 kpl - ei ehkä ilmoiteta korttejan määrää) i symbol - Ostamalla premium saat yhteensä 1300 korttia */}
                        <Button margin={"1%"} colorScheme='blue' onClick={() => navigate("/Biologia/1")}> Play </Button>
                        <CircularProgBar topicText={"Biologia1TotalScore"} />
                    </Center>
                </Box>

                <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                    <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                        <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Ekologia</Text>
                        <Button margin={"1%"} colorScheme='blue' onClick={() => navigate("/Biologia/2")}> Play </Button>
                        <CircularProgBar topicText={"Biologia2TotalScore"} />
                    </Center>
                </Box>

                <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                    <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                        <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Solu</Text>
                        <Button margin={"1%"} colorScheme='blue' onClick={() => navigate("/Biologia/3")}> Play </Button>
                        <CircularProgBar topicText={"Biologia3TotalScore"} />
                    </Center>
                </Box>

                <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                    <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                        <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Ihmis Biologia</Text>
                        <Button margin={"1%"} colorScheme='blue' onClick={() => navigate("/Biologia/4")}> Play </Button>
                        <CircularProgBar topicText={"Biologia4TotalScore"} />
                    </Center>
                </Box>

                <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                    <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                        <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Geenitekniikka</Text>
                        <Button margin={"1%"} colorScheme='blue' onClick={() => navigate("/Biologia/5")}> Play </Button>
                        <CircularProgBar topicText={"Biologia5TotalScore"} />
                    </Center>
                </Box>
            </Center>
        </>
    )
}

export default SubjectTopicDeckBox