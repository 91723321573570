import React, { useContext } from 'react';

import { GlobalContext } from '../components/GlobalContext/GlobalContext';


// Database Reference
import { db } from "../firebaseConfig"
import { collection, addDoc, getDoc, doc, setDoc } from "@firebase/firestore";



export default function AboutPage() {


  const { isSignedIn } = useContext(GlobalContext);

  const AddToDatabase = async () => {

    try {

      await setDoc(doc(db, "Cards", "Biologia", "Biologia1", "Card5"), {

        type: 1,
        questionText: "Mitä on biologia?2",
        NumberOfAnswers: 4,
        answerOptions: [
          { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita", correctOption: true },
          { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia", correctOption: false },
          { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä", correctOption: false },
          { answerText: "Tieteellinen tiede, joka tutkii eläviä olioita ja niiden ominaisuuksia sekä niiden kehitystä sekä niiden ympäristöä", correctOption: false },
        ],

      }, {merge: true})
      console.log("Done")
    }
    catch (error) {
      console.log(error)
    }

  }




  return (
    <>
      <div>AboutPage</div>

      {/* --------- ADD CARDS TO THE DATABASE --------- */}

      <button onClick={AddToDatabase}> AddToDatabase </button>







      <button onClick={() => console.log(isSignedIn)}> Context </button>
    </>
  )
}
