import React, { useContext, useEffect } from 'react';

import { GlobalContext } from './components/GlobalContext/GlobalContext';

import './App.css';
import { Route, Routes } from 'react-router-dom';

import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import Navbar from './components/Navbar';

import HomePagePremium from './pages/HomePagePremium';
import HomePageGuest from './pages/HomePageGuest';
import AdminSivu from './pages/AdminSivu';


import AboutPage from './pages/AboutPage';


import BiologiaPage from './pages/BiologiaPage';

import BiologiaPage1 from "./pages/BiologiaPages/BiologiaPage1";
import BiologiaPage2 from "./pages/BiologiaPages/BiologiaPage2";
import BiologiaPage3 from "./pages/BiologiaPages/BiologiaPage3";
import BiologiaPage4 from "./pages/BiologiaPages/BiologiaPage4";
import BiologiaPage5 from "./pages/BiologiaPages/BiologiaPage5";


import FysiikkaPage from './pages/FysiikkaPage';

import KemiaPage from './pages/KemiaPage';


function App() {

  const { isSignedIn, setIsSignedIn, isPremium, setIsPremium, setUser } = useContext(GlobalContext);


  useEffect(() => {

    if (localStorage.getItem("user")) {

      setIsSignedIn(true);

      const StorageUser = JSON.parse(localStorage.getItem("user"));
      setUser(StorageUser);


      if (StorageUser.CodecLite) {
        setIsPremium(true);
      } else {
        setIsPremium(false);
      }

    }
  }, []);


  return (
    <>
      <div className='apptest'>
        <Navbar />
        <Routes>

          <Route path="*" element={<NotFoundPage />} />

          {isSignedIn ? (
            <>

              {
                isPremium ? (
                  <>
                    <Route path="/" element={<HomePagePremium />} />
                    <Route path="/Biologia" element={<BiologiaPage />} />



                    <Route path="/Biologia/1" element={<BiologiaPage1 />} />
                    <Route path="/Biologia/2" element={<BiologiaPage2 />} />
                    <Route path="/Biologia/3" element={<BiologiaPage3 />} />
                    <Route path="/Biologia/4" element={<BiologiaPage4 />} />
                    <Route path="/Biologia/5" element={<BiologiaPage5 />} />



                    <Route path="/Fysiikka" element={<FysiikkaPage />} />
                    <Route path="/Kemia" element={<KemiaPage />} />
                  </>

                ) : (

                  <Route path="/" element={<HomePageGuest />} />
                )
              }


              <Route path="/about" element={<AboutPage />} />
              <Route path="/profile" element={<ProfilePage />} />


            </>
          ) : (
            <>
              <Route path="/" element={<HomePageGuest />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/about" element={<AboutPage />} />
            </>
          )

          }

          <Route path="/adminsivu" element={<AdminSivu />} />
        </Routes>
      </div>

    </>
  );
}

export default App;
