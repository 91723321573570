import React, { useState } from 'react';
import "../styles/Quiz.css";
import {
    Box,
    Button,
    Text,
    Center,
    Link
} from '@chakra-ui/react';
import Quiz from './Quiz';

function QuizGame({ data, topic }) {

    const [PlayTheGame, setPlayTheGame] = useState(false);

    const [questionNumber, setQuestionNumber] = useState(0);

    const [localStorageData, setLocalStorageData] = useState(null);

    const [UsableExistingArray, setUsableExistingArray] = useState(null);

    const [showEndOfGame, setShowEndOfGame] = useState(false);

    const topicNumber = topic === "BG1" ? 0 : topic === "BG2" ? 1 : topic === "BG3" ? 2 : topic === "BG4" ? 3 : topic === "BG5" ? 4 : 5

    const DataArray = Object.entries(Object.entries(data)[topicNumber][1]);


    function WrongAnswer(QuestionsNumber) {

        setQuestionNumber(questionNumber + 1)

        if (Number(UsableExistingArray[UsableExistingArray.length - 1]) === Number(QuestionsNumber)) {
            setShowEndOfGame(true)
            console.log("SET RUE SHOW END OF GMAE")
        }

    }

    function CorrectAnswer(QuestionsNumber) {

        var existing = localStorage.getItem(topic);

        existing = existing ? JSON.parse(existing) : {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0
        };

        existing[QuestionsNumber] = existing[QuestionsNumber] + 1;
        localStorage.setItem(topic, JSON.stringify(existing))

        setQuestionNumber(questionNumber + 1)
        setLocalStorageData(existing)

        console.log("Last usablearray", UsableExistingArray[UsableExistingArray.length - 1], "and questions number: ", QuestionsNumber)

        if (Number(UsableExistingArray[UsableExistingArray.length - 1]) === Number(QuestionsNumber)) {
            setShowEndOfGame(true)
            console.log("SET RUE SHOW END OF GMAE")
        }
    }


    // PROBLEM: IF THERE IS MULTIPLE 5 QUESTIONS IN THE END IT JUST GETS ONE
    function StartTheGame() {

        var Array0Points = []
        var Array1Points = []
        var Array2Points = []
        var Array3Points = []
        var Array4Points = []
        var Array5Points = []


        var existing = localStorage.getItem(topic);

        // Add 3 buttons for flashcard fill in the blank and so on: En osaa yhtään, en ole ihan varmaa, osaan hyvin
        existing = existing ? JSON.parse(existing) : {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0
        };

        setLocalStorageData(existing)

        localStorage.setItem(topic, JSON.stringify(existing))

        /* 
        TACTIC:

        1. Choose 2 x 0 point questions
        2. Choose 2 x 1 point questions 
        3. Choose 2 x 2 point questions
        4. Choose 2 x 3 point question
        5. Choose 1 x 4 point question
        6. Choose 1 x 5 point question

        if there is no 5 point question, choose 2 x 4 point question instead

        if there is no 4 point question, 
        choose 3 x 3 point question instead 
        but if there is no 5 point and 4 point question, choose 4 x 3 point question 
        
        YEAH! AND SO ON!

        */

        Object.entries(existing).map((item) => {
            if (item[1] <= 5) {
                if (item[1] === 0) {
                    Array0Points.push(Number(item[0]));
                } else if (item[1] === 1) {
                    Array1Points.push(Number(item[0]));
                } else if (item[1] === 2) {
                    Array2Points.push(Number(item[0]));
                } else if (item[1] === 3) {
                    Array3Points.push(Number(item[0]));
                } else if (item[1] === 4) {
                    Array4Points.push(Number(item[0]));
                } else if (item[1] === 5) {
                    Array5Points.push(Number(item[0]));
                }
            }
            return null
        })

        // Randomize the order of question numbers
        Array0Points.sort(() => (Math.random() > .5) ? 1 : -1)
        Array1Points.sort(() => (Math.random() > .5) ? 1 : -1)
        Array2Points.sort(() => (Math.random() > .5) ? 1 : -1)
        Array3Points.sort(() => (Math.random() > .5) ? 1 : -1)
        Array4Points.sort(() => (Math.random() > .5) ? 1 : -1)
        Array5Points.sort(() => (Math.random() > .5) ? 1 : -1)

        console.log("Array0Points: ", Array0Points, Array0Points.length)
        console.log("Array1Points: ", Array1Points, Array1Points.length)
        console.log("Array2Points: ", Array2Points, Array2Points.length)
        console.log("Array3Points: ", Array3Points, Array3Points.length)
        console.log("Array4Points: ", Array4Points, Array4Points.length)
        console.log("Array5Points: ", Array5Points, Array5Points.length)

        var Array0PointsCopy = [...Array0Points]
        var Array1PointsCopy = [...Array1Points]
        var Array2PointsCopy = [...Array2Points]
        var Array3PointsCopy = [...Array3Points]
        var Array4PointsCopy = [...Array4Points]
        var Array5PointsCopy = [...Array5Points]

        console.log("----------------------------")

        var howManyToAsk0 = 3 // 2--> 3
        var howManyToAsk1 = 2
        var howManyToAsk2 = 2
        var howManyToAsk3 = 1 // 2 --> 1
        var howManyToAsk4 = 1
        var howManyToAsk5 = 1

        var countOfPlusQuestionsWeNeed0 = 0
        var countOfPlusQuestionsWeNeed1 = 0
        var countOfPlusQuestionsWeNeed2 = 0
        var countOfPlusQuestionsWeNeed3 = 0
        var countOfPlusQuestionsWeNeed4 = 0
        var countOfPlusQuestionsWeNeed5 = 0



        // Check if array5 gives same amount as hwmanytoask


        if (Array5PointsCopy.pop() === undefined) {
            howManyToAsk5 -= 1;
            countOfPlusQuestionsWeNeed5 += 1;
        }
        if (Array4PointsCopy.pop() === undefined) {
            howManyToAsk4 -= 1;
            countOfPlusQuestionsWeNeed4 += 1;
        }


        var i3 = 0;
        while (i3 < 1) { // 2 --> 1
            if (Array3PointsCopy.pop() === undefined) {
                howManyToAsk3 -= 1
                countOfPlusQuestionsWeNeed3 += 1;
            }
            i3 += 1;
        }

        var i2 = 0;
        while (i2 < 2) {
            if (Array2PointsCopy.pop() === undefined) {
                howManyToAsk2 -= 1
                countOfPlusQuestionsWeNeed2 += 1;
            }
            i2 += 1;
        }

        var i1 = 0;
        while (i1 < 2) {
            if (Array1PointsCopy.pop() === undefined) {
                howManyToAsk1 -= 1
                countOfPlusQuestionsWeNeed1 += 1;
            }
            i1 += 1;
        }

        var i0 = 0;
        while (i0 < 3) { // 2 --> 3
            if (Array0PointsCopy.pop() === undefined) {
                howManyToAsk0 -= 1
                countOfPlusQuestionsWeNeed0 += 1;
            }
            i0 += 1;
        }

        while (countOfPlusQuestionsWeNeed5 > 0) {
            if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 } // Add random that first array is 4/3
            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
            countOfPlusQuestionsWeNeed5 -= 1
        }
        while (countOfPlusQuestionsWeNeed4 > 0) {
            if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
            countOfPlusQuestionsWeNeed4 -= 1
        }
        while (countOfPlusQuestionsWeNeed3 > 0) {
            if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
            countOfPlusQuestionsWeNeed3 -= 1
        }
        while (countOfPlusQuestionsWeNeed2 > 0) {
            if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
            countOfPlusQuestionsWeNeed2 -= 1
        }
        while (countOfPlusQuestionsWeNeed1 > 0) {
            if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
            countOfPlusQuestionsWeNeed1 -= 1
        }

        console.log("howManyToAsk0: ", howManyToAsk0)
        console.log("howManyToAsk1: ", howManyToAsk1)
        console.log("howManyToAsk2: ", howManyToAsk2)
        console.log("howManyToAsk3: ", howManyToAsk3)
        console.log("howManyToAsk4: ", howManyToAsk4)
        console.log("howManyToAsk5: ", howManyToAsk5)

        var tempArray = []

        while (howManyToAsk5 > 0) {
            tempArray.push(Array5Points.pop())
            howManyToAsk5 -= 1
        }
        while (howManyToAsk4 > 0) {
            tempArray.push(Array4Points.pop())
            howManyToAsk4 -= 1
        }
        while (howManyToAsk3 > 0) {
            tempArray.push(Array3Points.pop())
            howManyToAsk3 -= 1
        }
        while (howManyToAsk2 > 0) {
            tempArray.push(Array2Points.pop())
            howManyToAsk2 -= 1
        }
        while (howManyToAsk1 > 0) {
            tempArray.push(Array1Points.pop())
            howManyToAsk1 -= 1
        }
        while (howManyToAsk0 > 0) {
            tempArray.push(Array0Points.pop())
            howManyToAsk0 -= 1
        }

        console.log("TEMPARRAY: ", tempArray)



        setUsableExistingArray(tempArray)
        setPlayTheGame(true)
    }


    return (
        <>
            {
                PlayTheGame ? (
                    <>

                        {
                            UsableExistingArray && UsableExistingArray.length > 0 && UsableExistingArray.map((QuestionNumber, index) => {

                                //console.log(QuestionNumber, "QuestionNumber")
                                //console.log(index, "index")
                                //console.log(UsableExistingArray, "UsableExistingArray")

                                if (index === questionNumber) {
                                    return (
                                        <Quiz key={index} item={DataArray[QuestionNumber - 1]} index={QuestionNumber} CorrectAnswer={CorrectAnswer} WrongAnswer={WrongAnswer} LastQuestion={true} />
                                    )
                                }
                                return null

                            })

                        }

                        {

                            showEndOfGame &&
                            <>
                                <p> Onnea!  </p>
                                <Button colorScheme='blue' onClick={() => setPlayTheGame(false)}> Palaa takaisin alkuun ja aloita uudestaan</Button>
                            </>
                        }
                    </>
                ) : (

                    /* ALOITA PAGE */
                    <>
                        <Center>
                            <Box>
                                <Center display={"flex"} justifyContent={"center"} flexDirection={"column"} backgroundColor={"gray.50"} borderWidth={"1.2px"} borderRadius={"2xl"} width={["2xl", "2xl", "2xl", "2xl"]}>
                                    <Text fontWeight={600} fontSize={"2xl"} padding={"0.5%"} marginTop={"1%"}>{topic === "BG1" ? "Alku" : topic === "BG2" ? "Ekologia" : topic === "BG3" ? "Solu" : topic === "BG4" ? "bg444" : topic === "BG5" ? "ASDasdasd" : "aksdhkhasd"}</Text>

                                    <Box className="TopicPageBody" margin={"2%"}>
                                        <Text textAlign={"center"}> {DataArray[0][1].AlkuDescription} </Text>
                                    </Box>

                                    <a href="/#" className="btn" onClick={() => { setShowEndOfGame(false); setUsableExistingArray(null); setQuestionNumber(0); StartTheGame() }}>Aloita</a>
                                </Center>
                            </Box>
                        </Center>
                    </>
                )

            }

        </>
    )
}


export default QuizGame