import React, { useState } from 'react';
import {
    Button,
    Text,
    Box,
    Popover,
    PopoverTrigger,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverContent
} from "@chakra-ui/react";

import { IoMdHelp } from "react-icons/io";


function HintButton({ hint }) {


    return (
        <Box float={"right"} marginRight={"1%"}>
            <Popover>
                <PopoverTrigger>
                    <Button marginTop={"-5%"} size={"lg"} rightIcon={<IoMdHelp />} colorScheme='green' borderRadius={100}>Vihje</Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>{hint}</PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    )
}

export default HintButton