import React, { useEffect, useRef, useState } from 'react';
import "../styles/Quiz.css";
import HintButton from './QuizButtons/HintButton';
import FeedbackButton from './QuizButtons/FeedbackButton';

import { motion, useDragControls } from 'framer-motion';

import { RiArrowGoBackLine } from 'react-icons/ri';

import {
    Button,
    Box,
    Image,
    Text,
    FormControl,
    Input,
    FormLabel,
    InputGroup,
    InputRightElement,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Center,
} from '@chakra-ui/react'


function BeforeCorrectAnswerBox({ QuestionsNumber, Type3BeforeCorrectAnswer, setType3BeforeCorrectAnswer, CorrectAnswer }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [FeedbackButtonOpen, setFeedbackButtonOpen] = useState(false);
    const [FeedbackButtonClose, setFeedbackButtonClose] = useState(true);

    useEffect(() => {
        onOpen()
    }, [])

    if (Type3BeforeCorrectAnswer) {

        return (
            <>
                <div className="Type3BeforeCorrectAnswerBox">
                    <p>Oikea Vastaus!</p>
                    {/* Here get items lisätieto osa, jonka näkee kun vastaa oikein */}
                    <Button colorScheme='green' onClick={() => { CorrectAnswer(QuestionsNumber); setType3BeforeCorrectAnswer(false) }}>Seuraava Kysymys</Button>

                </div>


                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader color="#276749">
                            Vastasit Oikein!
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        </ModalBody>

                        <ModalFooter>

                            <Button position={"absolute"} top={"max"} left={"5%"} variant='outline' size="xs" onClick={() => { setFeedbackButtonClose(!FeedbackButtonClose); setFeedbackButtonOpen(!FeedbackButtonOpen) }}>Palaute Tehtävästä</Button>

                            <FeedbackButton setFeedbackButtonOpen={setFeedbackButtonOpen} setFeedbackButtonClose={setFeedbackButtonClose} FeedbackButtonClose={FeedbackButtonClose} FeedbackButtonOpen={FeedbackButtonOpen} QuestionsNumber={QuestionsNumber} />

                            <Button colorScheme='green' mr={3} onClick={() => { onClose(); CorrectAnswer(QuestionsNumber); setType3BeforeCorrectAnswer(false) }}>
                                Seuraava Kysymys
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>

        )

    }
}

function BeforeWrongAnswerBox({ QuestionsNumber, Type3BeforeWrongAnswer, setType3BeforeWrongAnswer, WrongAnswer }) {

    const [WrongAnswerBoxOpen, setWrongAnswerBoxOpen] = useState(true);

    const [FeedbackButtonOpen, setFeedbackButtonOpen] = useState(false);
    const [FeedbackButtonClose, setFeedbackButtonClose] = useState(true);

    if (Type3BeforeWrongAnswer) {
        return (
            <>

                <div className="Type3BeforeCorrectAnswerBox">
                    <p>Väärä Vastaus!</p>
                    {/* Here get items lisätieto osa, jonka näkee kun vastaa oikein */}
                    <Button colorScheme='red' onClick={() => { WrongAnswer(QuestionsNumber); setType3BeforeWrongAnswer(false) }}>Seuraava Kysymys</Button>

                </div>

                <Modal isOpen={WrongAnswerBoxOpen} onClose={() => { setWrongAnswerBoxOpen(!WrongAnswerBoxOpen) }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader color="red">
                            Vastasit Väärin!
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        </ModalBody>

                        <ModalFooter>

                            <Button position={"absolute"} top={"max"} left={"5%"} variant='outline' size="xs" onClick={() => { setFeedbackButtonClose(!FeedbackButtonClose); setFeedbackButtonOpen(!FeedbackButtonOpen) }}>Palaute Tehtävästä</Button>

                            <FeedbackButton setFeedbackButtonOpen={setFeedbackButtonOpen} setFeedbackButtonClose={setFeedbackButtonClose} FeedbackButtonClose={FeedbackButtonClose} FeedbackButtonOpen={FeedbackButtonOpen} QuestionsNumber={QuestionsNumber} />

                            <Button colorScheme='red' mr={3} onClick={() => { setWrongAnswerBoxOpen(!WrongAnswerBoxOpen); WrongAnswer(QuestionsNumber); setType3BeforeWrongAnswer(false) }}>
                                Seuraava Kysymys
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </>


        )
    }
}


// ADD BUTTON FOR FEEDBACK THAT SENDS TO DATABASE WITH item[1].name


function Quiz({ item, index, CorrectAnswer, WrongAnswer }) {

    var stringSimilarity = require("string-similarity");

    const [Type4EndGameDiplay, setType4EndGameDiplay] = useState("visible");
    const [Type4DisplayImage, setType4DisplayImage] = useState("hidden");
    const [Type4WidhtOfTheBox, setType4WidhtOfTheBox] = useState(0);
    const [Type4HeightOfTheBox, setType4HeightOfTheBox] = useState(0);
    const Type4HeightOfTheDivRef = useRef(null);
    const [Type4HeightOfTheImage, setType4HeightOfTheImage] = useState(0);
    const [Type4WidhtOfTheImage, setType4WidhtOfTheImage] = useState(0);
    const Type4HeightOfTheImageRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure()

    // Button 1
    const [Type4AnswerButtonColor1, setType4AnswerButtonColor1] = useState("#41defa");
    const [Type4AnswerButtonConnect1, setType4AnswerButtonConnect1] = useState(null);
    const Type4Button1Ref = useRef(null);
    const [Type4DragCordsOfAnswer1, setType4DragCordsOfAnswer1] = useState({ x: 0, y: 0 });

    // Button 2
    const [Type4AnswerButtonColor2, setType4AnswerButtonColor2] = useState("#41defa");
    const [Type4AnswerButtonConnect2, setType4AnswerButtonConnect2] = useState(null);
    const Type4Button2Ref = useRef(null);
    const [Type4DragCordsOfAnswer2, setType4DragCordsOfAnswer2] = useState({ x: 0, y: 0 });

    // Button 3
    const [Type4AnswerButtonColor3, setType4AnswerButtonColor3] = useState("#41defa");
    const [Type4AnswerButtonConnect3, setType4AnswerButtonConnect3] = useState(null);
    const Type4Button3Ref = useRef(null);
    const [Type4DragCordsOfAnswer3, setType4DragCordsOfAnswer3] = useState({ x: 0, y: 0 });

    // Button 4
    const [Type4AnswerButtonColor4, setType4AnswerButtonColor4] = useState("#41defa");
    const [Type4AnswerButtonConnect4, setType4AnswerButtonConnect4] = useState(null);
    const Type4Button4Ref = useRef(null);
    const [Type4DragCordsOfAnswer4, setType4DragCordsOfAnswer4] = useState({ x: 0, y: 0 });

    // Button 5
    const [Type4AnswerButtonColor5, setType4AnswerButtonColor5] = useState("#41defa");
    const [Type4AnswerButtonConnect5, setType4AnswerButtonConnect5] = useState(null);
    const Type4Button5Ref = useRef(null);
    const [Type4DragCordsOfAnswer5, setType4DragCordsOfAnswer5] = useState({ x: 0, y: 0 });


    useEffect(() => {
        ImageWHSet();
        item[1].svgCoordinates && item[1].svgCoordinates[0] && setType4AnswerButtonConnect1(0);
        item[1].svgCoordinates && item[1].svgCoordinates[1] && setType4AnswerButtonConnect2(0);
        item[1].svgCoordinates && item[1].svgCoordinates[2] && setType4AnswerButtonConnect3(0);
        item[1].svgCoordinates && item[1].svgCoordinates[3] && setType4AnswerButtonConnect4(0);
        item[1].svgCoordinates && item[1].svgCoordinates[4] && setType4AnswerButtonConnect5(0);

    }, [Type4HeightOfTheDivRef, Type4HeightOfTheImageRef]);

    const ImageWHSet = () => {
        Type4HeightOfTheImageRef && Type4HeightOfTheImageRef.current && setType4HeightOfTheImage(Type4HeightOfTheImageRef.current.clientHeight);
        Type4HeightOfTheImageRef && Type4HeightOfTheImageRef.current && setType4WidhtOfTheImage(Type4HeightOfTheImageRef.current.clientWidth);
        Type4HeightOfTheDivRef && Type4HeightOfTheDivRef.current && setType4HeightOfTheBox(Type4HeightOfTheDivRef.current.clientHeight);
        Type4HeightOfTheDivRef && Type4HeightOfTheDivRef.current && setType4WidhtOfTheBox(Type4HeightOfTheDivRef.current.clientWidth);
    }

    const ChangePosButton1 = (item) => {

        console.log("Button", Type4Button1Ref.current.getClientRects()[0], "Div", Type4HeightOfTheDivRef.current.getClientRects()[0], "image", Type4HeightOfTheImageRef.current.getClientRects()[0])
        console.log(item.svgCoordinates[0])

        // Start pixel of image x and y and then 50% of the image width and height
        if (item.svgCoordinates[0]) {
            var svgCord1 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].y) / 100
            }
        }
        if (item.svgCoordinates[1]) {
            var svgCord2 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].y) / 100
            }
        }
        if (item.svgCoordinates[2]) {
            var svgCord3 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].y) / 100
            }
        }
        if (item.svgCoordinates[3]) {
            var svgCord4 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].y) / 100
            }
        }


        console.log("SVG CORD 1", svgCord1.x, svgCord1.y)
        console.log("SVG CORD 2", svgCord2.x, svgCord2.y)
        console.log("SVG CORD 3", svgCord3.x, svgCord3.y)
        console.log("SVG CORD 4", svgCord4.x, svgCord4.y)

        if ((svgCord1 && svgCord1.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
            Type4Button1Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
            svgCord1.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
            Type4Button1Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100)
            ||
            (svgCord2 && svgCord2.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100)
            ||
            (svgCord3 && svgCord3.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100)
            ||
            (svgCord4 && svgCord4.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100)
        ) {
            setType4AnswerButtonColor1("#13d139")
            console.log(svgCord1.x)
            console.log(Type4Button1Ref.current.getClientRects()[0].x)
            console.log(svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100)


            // SVG CORD 1
            if (svgCord1 && svgCord1.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
                svgCord1.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100
            ) {
                console.log("Button Connected with: SVG CORD 1")
                setType4AnswerButtonConnect1(item.svgCoordinates[0].id)
            }
            else if (svgCord2 && svgCord2.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100
            ) {
                console.log("Button Connected with: SVG CORD 2")
                setType4AnswerButtonConnect1(item.svgCoordinates[1].id)
            }
            else if (svgCord3 && svgCord3.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100
            ) {
                console.log("Button Connected with: SVG CORD 3")
                setType4AnswerButtonConnect1(item.svgCoordinates[2].id)
            }
            else if (svgCord4 && svgCord4.x < Type4Button1Ref.current.getClientRects()[0].x + Type4Button1Ref.current.getClientRects()[0].width &&
                Type4Button1Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button1Ref.current.getClientRects()[0].y + Type4Button1Ref.current.getClientRects()[0].height &&
                Type4Button1Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100
            ) {
                console.log("Button Connected with: SVG CORD 4")
                setType4AnswerButtonConnect1(item.svgCoordinates[3].id)
            }
        }
        else {
            setType4AnswerButtonColor1("#41defa")
            setType4AnswerButtonConnect1(0)
        }

    }

    const ChangePosButton2 = (item) => {

        console.log("Button", Type4Button2Ref.current.getClientRects()[0], "Div", Type4HeightOfTheDivRef.current.getClientRects()[0], "image", Type4HeightOfTheImageRef.current.getClientRects()[0])
        console.log(item.svgCoordinates[0])

        // Start pixel of image x and y and then 50% of the image width and height
        if (item.svgCoordinates[0]) {
            var svgCord1 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].y) / 100
            }
        }
        if (item.svgCoordinates[1]) {
            var svgCord2 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].y) / 100
            }
        }
        if (item.svgCoordinates[2]) {
            var svgCord3 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].y) / 100
            }
        }
        if (item.svgCoordinates[3]) {
            var svgCord4 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].y) / 100
            }
        }


        console.log("SVG CORD 1", svgCord1.x, svgCord1.y)
        console.log("SVG CORD 2", svgCord2.x, svgCord2.y)
        console.log("SVG CORD 3", svgCord3.x, svgCord3.y)
        console.log("SVG CORD 4", svgCord4.x, svgCord4.y)

        if ((svgCord1 && svgCord1.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
            Type4Button2Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
            svgCord1.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
            Type4Button2Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100)
            ||
            (svgCord2 && svgCord2.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100)
            ||
            (svgCord3 && svgCord3.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100)
            ||
            (svgCord4 && svgCord4.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100)
        ) {
            setType4AnswerButtonColor2("#13d139")
            console.log(svgCord1.x)
            console.log(Type4Button2Ref.current.getClientRects()[0].x)
            console.log(svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100)


            // SVG CORD 1
            if (svgCord1 && svgCord1.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
                svgCord1.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 1")
                setType4AnswerButtonConnect2(item.svgCoordinates[0].id)
            }
            else if (svgCord2 && svgCord2.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 2")
                setType4AnswerButtonConnect2(item.svgCoordinates[1].id)
            }
            else if (svgCord3 && svgCord3.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 3")
                setType4AnswerButtonConnect2(item.svgCoordinates[2].id)
            }
            else if (svgCord4 && svgCord4.x < Type4Button2Ref.current.getClientRects()[0].x + Type4Button2Ref.current.getClientRects()[0].width &&
                Type4Button2Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button2Ref.current.getClientRects()[0].y + Type4Button2Ref.current.getClientRects()[0].height &&
                Type4Button2Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 4")
                setType4AnswerButtonConnect2(item.svgCoordinates[3].id)
            }
        }
        else {
            setType4AnswerButtonColor2("#41defa")
            setType4AnswerButtonConnect2(0)
        }

    }

    const ChangePosButton3 = (item) => {

        console.log("Button", Type4Button3Ref.current.getClientRects()[0], "Div", Type4HeightOfTheDivRef.current.getClientRects()[0], "image", Type4HeightOfTheImageRef.current.getClientRects()[0])
        console.log(item.svgCoordinates[0])

        // Start pixel of image x and y and then 50% of the image width and height
        if (item.svgCoordinates[0]) {
            var svgCord1 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].y) / 100
            }
        }
        if (item.svgCoordinates[1]) {
            var svgCord2 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].y) / 100
            }
        }
        if (item.svgCoordinates[2]) {
            var svgCord3 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].y) / 100
            }
        }
        if (item.svgCoordinates[3]) {
            var svgCord4 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].y) / 100
            }
        }


        console.log("SVG CORD 1", svgCord1.x, svgCord1.y)
        console.log("SVG CORD 2", svgCord2.x, svgCord2.y)
        console.log("SVG CORD 3", svgCord3.x, svgCord3.y)
        console.log("SVG CORD 4", svgCord4.x, svgCord4.y)

        if ((svgCord1 && svgCord1.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
            Type4Button3Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
            svgCord1.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
            Type4Button3Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100)
            ||
            (svgCord2 && svgCord2.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100)
            ||
            (svgCord3 && svgCord3.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100)
            ||
            (svgCord4 && svgCord4.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100)
        ) {
            setType4AnswerButtonColor3("#13d139")
            console.log(svgCord1.x)
            console.log(Type4Button3Ref.current.getClientRects()[0].x)
            console.log(svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100)


            // SVG CORD 1
            if (svgCord1 && svgCord1.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
                svgCord1.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 1")
                setType4AnswerButtonConnect3(item.svgCoordinates[0].id)
            }
            else if (svgCord2 && svgCord2.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 2")
                setType4AnswerButtonConnect3(item.svgCoordinates[1].id)
            }
            else if (svgCord3 && svgCord3.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 3")
                setType4AnswerButtonConnect3(item.svgCoordinates[2].id)
            }
            else if (svgCord4 && svgCord4.x < Type4Button3Ref.current.getClientRects()[0].x + Type4Button3Ref.current.getClientRects()[0].width &&
                Type4Button3Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button3Ref.current.getClientRects()[0].y + Type4Button3Ref.current.getClientRects()[0].height &&
                Type4Button3Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 4")
                setType4AnswerButtonConnect3(item.svgCoordinates[3].id)
            }
        }
        else {
            setType4AnswerButtonColor3("#41defa")
            setType4AnswerButtonConnect3(0)
        }

    }

    const ChangePosButton4 = (item) => {

        console.log("Button", Type4Button4Ref.current.getClientRects()[0], "Div", Type4HeightOfTheDivRef.current.getClientRects()[0], "image", Type4HeightOfTheImageRef.current.getClientRects()[0])
        console.log(item.svgCoordinates[0])

        // Start pixel of image x and y and then 50% of the image width and height
        if (item.svgCoordinates[0]) {
            var svgCord1 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].y) / 100
            }
        }
        if (item.svgCoordinates[1]) {
            var svgCord2 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].y) / 100
            }
        }
        if (item.svgCoordinates[2]) {
            var svgCord3 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].y) / 100
            }
        }
        if (item.svgCoordinates[3]) {
            var svgCord4 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].y) / 100
            }
        }


        console.log("SVG CORD 1", svgCord1.x, svgCord1.y)
        console.log("SVG CORD 2", svgCord2.x, svgCord2.y)
        console.log("SVG CORD 3", svgCord3.x, svgCord3.y)
        console.log("SVG CORD 4", svgCord4.x, svgCord4.y)

        if ((svgCord1 && svgCord1.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
            Type4Button4Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
            svgCord1.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
            Type4Button4Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100)
            ||
            (svgCord2 && svgCord2.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100)
            ||
            (svgCord3 && svgCord3.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100)
            ||
            (svgCord4 && svgCord4.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100)
        ) {
            setType4AnswerButtonColor4("#13d139")
            console.log(svgCord1.x)
            console.log(Type4Button4Ref.current.getClientRects()[0].x)
            console.log(svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100)


            // SVG CORD 1
            if (svgCord1 && svgCord1.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
                svgCord1.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 1")
                setType4AnswerButtonConnect4(item.svgCoordinates[0].id)
            }
            else if (svgCord2 && svgCord2.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 2")
                setType4AnswerButtonConnect4(item.svgCoordinates[1].id)
            }
            else if (svgCord3 && svgCord3.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 3")
                setType4AnswerButtonConnect4(item.svgCoordinates[2].id)
            }
            else if (svgCord4 && svgCord4.x < Type4Button4Ref.current.getClientRects()[0].x + Type4Button4Ref.current.getClientRects()[0].width &&
                Type4Button4Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button4Ref.current.getClientRects()[0].y + Type4Button4Ref.current.getClientRects()[0].height &&
                Type4Button4Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 4")
                setType4AnswerButtonConnect4(item.svgCoordinates[3].id)
            }
        }
        else {
            setType4AnswerButtonColor4("#41defa")
            setType4AnswerButtonConnect4(0)
        }

    }

    const ChangePosButton5 = (item) => {

        console.log("Button", Type4Button5Ref.current.getClientRects()[0], "Div", Type4HeightOfTheDivRef.current.getClientRects()[0], "image", Type4HeightOfTheImageRef.current.getClientRects()[0])
        console.log(item.svgCoordinates[0])

        // Start pixel of image x and y and then 50% of the image width and height
        if (item.svgCoordinates[0]) {
            var svgCord1 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].y) / 100
            }
        }
        if (item.svgCoordinates[1]) {
            var svgCord2 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].y) / 100
            }
        }
        if (item.svgCoordinates[2]) {
            var svgCord3 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].y) / 100
            }
        }
        if (item.svgCoordinates[3]) {
            var svgCord4 = {
                x: Type4HeightOfTheImageRef.current.getClientRects()[0].x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].x) / 100,
                y: Type4HeightOfTheImageRef.current.getClientRects()[0].y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].y) / 100
            }
        }


        console.log("SVG CORD 1", svgCord1.x, svgCord1.y)
        console.log("SVG CORD 2", svgCord2.x, svgCord2.y)
        console.log("SVG CORD 3", svgCord3.x, svgCord3.y)
        console.log("SVG CORD 4", svgCord4.x, svgCord4.y)

        if ((svgCord1 && svgCord1.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
            Type4Button5Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
            svgCord1.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
            Type4Button5Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100)
            ||
            (svgCord2 && svgCord2.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100)
            ||
            (svgCord3 && svgCord3.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100)
            ||
            (svgCord4 && svgCord4.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100)
        ) {
            setType4AnswerButtonColor5("#13d139")
            console.log(svgCord1.x)
            console.log(Type4Button5Ref.current.getClientRects()[0].x)
            console.log(svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100)


            // SVG CORD 1
            if (svgCord1 && svgCord1.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord1.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[0].width) / 100 &&
                svgCord1.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord1.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[0].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 1")
                setType4AnswerButtonConnect5(item.svgCoordinates[0].id)
            }
            else if (svgCord2 && svgCord2.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord2.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[1].width) / 100 &&
                svgCord2.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord2.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[1].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 2")
                setType4AnswerButtonConnect5(item.svgCoordinates[1].id)
            }
            else if (svgCord3 && svgCord3.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord3.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[2].width) / 100 &&
                svgCord3.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord3.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[2].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 3")
                setType4AnswerButtonConnect5(item.svgCoordinates[2].id)
            }
            else if (svgCord4 && svgCord4.x < Type4Button5Ref.current.getClientRects()[0].x + Type4Button5Ref.current.getClientRects()[0].width &&
                Type4Button5Ref.current.getClientRects()[0].x < svgCord4.x + Type4HeightOfTheImageRef.current.getClientRects()[0].width * parseFloat(item.svgCoordinates[3].width) / 100 &&
                svgCord4.y < Type4Button5Ref.current.getClientRects()[0].y + Type4Button5Ref.current.getClientRects()[0].height &&
                Type4Button5Ref.current.getClientRects()[0].y < svgCord4.y + Type4HeightOfTheImageRef.current.getClientRects()[0].height * parseFloat(item.svgCoordinates[3].height) / 100
            ) {
                console.log("Button2 Connected with: SVG CORD 4")
                setType4AnswerButtonConnect5(item.svgCoordinates[3].id)
            }
        }
        else {
            setType4AnswerButtonColor5("#41defa")
            setType4AnswerButtonConnect5(0)
        }

    }

    const handleDragEndButton1 = () => {
        const modalElement = Type4Button1Ref?.current;
        if (modalElement) {
            const coords = modalElement.style.transform.match(/^translateX\((.+)px\) translateY\((.+)px\) translateZ/);
            if (coords?.length) {
                // Could add if off sreen then teleport to (0, 0) for example
                setType4DragCordsOfAnswer1({ x: parseInt(coords[1], 10), y: parseInt(coords[2], 10) })
            }
        }
        console.log(Type4DragCordsOfAnswer1)
        ChangePosButton1(item[1])
    };

    const handleDragEndButton2 = () => {
        const modalElement = Type4Button2Ref?.current;
        if (modalElement) {
            const coords = modalElement.style.transform.match(/^translateX\((.+)px\) translateY\((.+)px\) translateZ/);
            if (coords?.length) {
                // Could add if off sreen then teleport to (0, 0) for example
                setType4DragCordsOfAnswer2({ x: parseInt(coords[1], 10), y: parseInt(coords[2], 10) })
            }
        }
        console.log(Type4DragCordsOfAnswer2)
        ChangePosButton2(item[1])
    };

    const handleDragEndButton3 = () => {
        const modalElement = Type4Button3Ref?.current;
        if (modalElement) {
            const coords = modalElement.style.transform.match(/^translateX\((.+)px\) translateY\((.+)px\) translateZ/);
            if (coords?.length) {
                // Could add if off sreen then teleport to (0, 0) for example
                setType4DragCordsOfAnswer3({ x: parseInt(coords[1], 10), y: parseInt(coords[2], 10) })
            }
        }
        console.log(Type4DragCordsOfAnswer3)
        ChangePosButton3(item[1])
    };

    const handleDragEndButton4 = () => {
        const modalElement = Type4Button4Ref?.current;
        if (modalElement) {
            const coords = modalElement.style.transform.match(/^translateX\((.+)px\) translateY\((.+)px\) translateZ/);
            if (coords?.length) {
                // Could add if off sreen then teleport to (0, 0) for example
                setType4DragCordsOfAnswer4({ x: parseInt(coords[1], 10), y: parseInt(coords[2], 10) })
            }
        }
        console.log(Type4DragCordsOfAnswer4)
        ChangePosButton4(item[1])
    };

    const handleDragEndButton5 = () => {
        const modalElement = Type4Button4Ref?.current;
        if (modalElement) {
            const coords = modalElement.style.transform.match(/^translateX\((.+)px\) translateY\((.+)px\) translateZ/);
            if (coords?.length) {
                // Could add if off sreen then teleport to (0, 0) for example
                setType4DragCordsOfAnswer5({ x: parseInt(coords[1], 10), y: parseInt(coords[2], 10) })
            }
        }
        console.log(Type4DragCordsOfAnswer5)
        ChangePosButton5(item[1])
    };


    const Type4CheckAnswers = () => {
        // if there is 0 check it first ( is everything connected)
        if (Type4AnswerButtonConnect1 === 0 || Type4AnswerButtonConnect2 === 0 || Type4AnswerButtonConnect3 === 0 || Type4AnswerButtonConnect4 === 0 || Type4AnswerButtonConnect5 === 0) {
            onOpen();
        }
        // others else if below
        else if (Type4AnswerButtonConnect1 == 1 && Type4AnswerButtonConnect2 == 2 && (Type4AnswerButtonConnect3 == 3 || !Type4AnswerButtonConnect3) && (Type4AnswerButtonConnect4 == 4 || !Type4AnswerButtonConnect4) && (Type4AnswerButtonConnect5 == 5 || !Type4AnswerButtonConnect5)) {
            console.log("Correct Answer 1")
            setType3BeforeCorrectAnswer(true)
        }
        // else its wrong answers
        else {
            setType4EndGameDiplay("hidden")
            setType3BeforeWrongAnswer(true)
        }
    }

    const [Type6Button1, setType6Button1] = useState("");
    const [Type6Button2, setType6Button2] = useState("");
    const [Type6Button3, setType6Button3] = useState("");
    const [Type6Button4, setType6Button4] = useState("");
    const [Type6ButtonIsClicked1, setType6ButtonIsClicked1] = useState(false);
    const [Type6ButtonIsClicked2, setType6ButtonIsClicked2] = useState(false);
    const [Type6ButtonIsClicked3, setType6ButtonIsClicked3] = useState(false);
    const [Type6ButtonIsClicked4, setType6ButtonIsClicked4] = useState(false);
    const [Type6CorrectAnswerCount, setType6CorrectAnswerCount] = useState(0);
    const [Type6GameOver, setType6GameOver] = useState(false);

    const [Type7ShowFlashcard, setType7ShowFlashcard] = useState(false);
    const [Type7ShowNext, setType7ShowNext] = useState(false);

    const [Type3FillBlankWord, setType3FillBlankWord] = useState("");

    const [Type3BeforeCorrectAnswer, setType3BeforeCorrectAnswer] = useState(false);
    const [Type3BeforeWrongAnswer, setType3BeforeWrongAnswer] = useState(false);
    const [Type3CountOfSubmits, setType3CountOfSubmits] = useState(0);


    const Type3FillBlankhandleSubmit = (e) => {

        e.preventDefault();

        // By changing 0 to other numbers you can change how many times you can submit the answer
        if (Type3CountOfSubmits > 0) {
            return;
        }

        console.log(Type3FillBlankWord);

        var similarity = stringSimilarity.compareTwoStrings(item[1].correctAnswer && item[1].correctAnswer.toLowerCase(), Type3FillBlankWord.toLowerCase());
        setType3FillBlankWord("");

        // Raja 0.5
        if (similarity >= 0.5) {
            console.log("Sim Täsmää");
            setType3BeforeCorrectAnswer(true);
        }
        else {
            console.log("väärä vastaus")
            setType3BeforeWrongAnswer(true);
        }

        console.log(similarity)

        setType3CountOfSubmits(Type3CountOfSubmits + 1);
    }

    // Type 1: Multiple choice with text/image/video
    if (item[1].type === 1) {

        return (
            <>
                {item[1].hint && <HintButton hint={item[1].hint} />}

                <Center key={index} marginTop={"1%"}>
                    <Box>
                        <Box>
                            <Text fontSize={"xl"} fontWeight={"600"} className='Type1Question'> {item[1].questionText}</Text>
                        </Box>
                        <Box className='Type1AnswerOptionsDiv'>

                            <Button className='Type1AnswerOptionsDivButton'
                                colorScheme={Type6Button1 ? Type6Button1 : 'gray'}
                                whiteSpace={'normal !important'}
                                onClick={() => {
                                    if (item[1].answerOptions[0].correctOption && !Type6ButtonIsClicked1) {
                                        setType6Button1("green");
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 1);
                                        setType6ButtonIsClicked1(true)
                                    }
                                    else if (!item[1].answerOptions[0].correctOption && !Type6ButtonIsClicked1) {
                                        setType6Button1("red");
                                        setType6ButtonIsClicked1(true)
                                        setType6GameOver(true)
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 10);
                                    }
                                }}> {item[1].answerOptions[0].answerText}
                            </Button>

                            <Button className='Type1AnswerOptionsDivButton'
                                colorScheme={Type6Button2 ? Type6Button2 : "gray"}
                                whiteSpace={'normal !important'}
                                onClick={() => {
                                    if (item[1].answerOptions[1].correctOption && !Type6ButtonIsClicked2) {
                                        setType6Button2("green");
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 1);
                                        setType6ButtonIsClicked2(true)
                                    }
                                    else if (!item[1].answerOptions[1].correctOption && !Type6ButtonIsClicked2) {
                                        setType6Button2("red");
                                        setType6ButtonIsClicked2(true)
                                        setType6GameOver(true)
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 10);
                                    }
                                }}> {item[1].answerOptions[1].answerText}
                            </Button>

                            <Button className='Type1AnswerOptionsDivButton'
                                colorScheme={Type6Button3 ? Type6Button3 : 'gray'}
                                whiteSpace={'normal !important'}
                                onClick={() => {
                                    if (item[1].answerOptions[2].correctOption && !Type6ButtonIsClicked3) {
                                        setType6Button3("green");
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 1);
                                        setType6ButtonIsClicked3(true)
                                    }
                                    else if (!item[1].answerOptions[2].correctOption && !Type6ButtonIsClicked3) {
                                        setType6Button3("red");
                                        setType6ButtonIsClicked3(true)
                                        setType6GameOver(true)
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 10);
                                    }
                                }}> {item[1].answerOptions[2].answerText}
                            </Button>

                            <Button className='Type1AnswerOptionsDivButton'
                                colorScheme={Type6Button4 ? Type6Button4 : "gray"}
                                whiteSpace={'normal !important'}
                                onClick={() => {
                                    if (item[1].answerOptions[3].correctOption && !Type6ButtonIsClicked4) {
                                        setType6Button4("green");
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 1);
                                        setType6ButtonIsClicked4(true)
                                    }
                                    else if (!item[1].answerOptions[3].correctOption && !Type6ButtonIsClicked4) {
                                        setType6Button4("red");
                                        setType6ButtonIsClicked4(true)
                                        setType6GameOver(true)
                                        setType6CorrectAnswerCount(Type6CorrectAnswerCount + 10);
                                    }
                                }}>
                                {item[1].answerOptions[3].answerText}
                            </Button>

                        </Box>

                        {
                            Type6GameOver &&
                            <BeforeWrongAnswerBox QuestionsNumber={item[0]} Type3BeforeWrongAnswer={true} setType3BeforeWrongAnswer={setType3BeforeWrongAnswer} WrongAnswer={WrongAnswer} />
                        }

                        {
                            Type6CorrectAnswerCount === item[1].answerOptions.filter((item) => item.correctOption === true).length &&
                            <BeforeCorrectAnswerBox QuestionsNumber={item[0]} Type3BeforeCorrectAnswer={true} setType3BeforeCorrectAnswer={setType3BeforeCorrectAnswer} CorrectAnswer={CorrectAnswer} />
                        }

                    </Box>
                </Center>

            </>

        )
    }

    // Type 2: True or false with text/image/video
    else if (item[1].type === 2) {

        return (

            <>
                {item[1].hint && <HintButton hint={item[1].hint} />}


                <Center key={index} marginTop={"1%"}>
                    <Box>
                        <Box className='Type2TrueOrFalseDiv' backgroundColor={"gray.50"} width={"xl"}>

                            <Box marginTop={"1%"}>
                                <Text textAlign={"center"} fontWeight={600} paddingTop={"1%"} fontSize={"xl"} paddingBottom={"4%"} className='Type2TrueOrFalseQuestion'> {item[1].questionText}</Text>
                            </Box>
                            <Center marginLeft={"auto"} marginRight={"auto"} width={"80%"}>
                                {
                                    item[1].questionMedia &&
                                    <Image className='Type2TrueOrFalseMedia' src={item[1].questionMedia} alt="Question Media" />
                                }
                            </Center>

                            <Box className='Type2TrueOrFalseDivButtons'>
                                <Button className='Type2TrueOrFalseDivButton' onClick={() => { item[1].isItTrue ? setType3BeforeCorrectAnswer(true) : setType3BeforeWrongAnswer(true) }}> Kyllä </Button>
                                <Button className='Type2TrueOrFalseDivButton' onClick={() => { !item[1].isItTrue ? setType3BeforeCorrectAnswer(true) : setType3BeforeWrongAnswer(true) }}> Ei </Button>
                            </Box>

                            <BeforeCorrectAnswerBox QuestionsNumber={item[0]} Type3BeforeCorrectAnswer={Type3BeforeCorrectAnswer} setType3BeforeCorrectAnswer={setType3BeforeCorrectAnswer} CorrectAnswer={CorrectAnswer} />
                            <BeforeWrongAnswerBox QuestionsNumber={item[0]} Type3BeforeWrongAnswer={Type3BeforeWrongAnswer} setType3BeforeWrongAnswer={setType3BeforeWrongAnswer} WrongAnswer={WrongAnswer} />

                        </Box>
                    </Box>
                </Center>

            </>
        )
    }

    // Type 3: Fill in the blank with text/image/video
    else if (item[1].type === 3) {

        return (

            <>

                {item[1].hint && <HintButton hint={item[1].hint} />}

                <Center key={index} className='Type3FillBlankDiv' marginTop={"1%"}>

                    <Box maxWidth={"50%"}>

                        <Text textAlign={"center"} fontWeight={600} paddingTop={"1%"} fontSize={"xl"} paddingBottom={"2%"} className='Type3FillBlankQuestion'> {item[1].questionText}</Text>

                        {
                            item[1].questionMedia &&
                            <Image className='Type3FillBlankMedia' src={item[1].questionMedia} alt="Question Media" />
                        }

                        <InputGroup className='Type3FillBlankInput' alignContent={"center"} size='lg'>
                            <Input
                                value={Type3FillBlankWord}
                                onChange={(e) => setType3FillBlankWord(e.target.value)}
                                pr='4.5rem'
                                type={"text"}
                                placeholder='Kirjoita Vastaus'
                            />
                            <InputRightElement width='4.5rem'>
                                <Button colorScheme='blue' h='2rem' size='sm' onClick={Type3FillBlankhandleSubmit}>
                                    Kokeile
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                    </Box>


                </Center>

                {
                    Type3BeforeWrongAnswer &&
                    <Text> Oikea vastaus oli: {item[1].correctAnswer}</Text>
                }

                <BeforeCorrectAnswerBox QuestionsNumber={item[0]} Type3BeforeCorrectAnswer={Type3BeforeCorrectAnswer} setType3BeforeCorrectAnswer={setType3BeforeCorrectAnswer} CorrectAnswer={CorrectAnswer} />
                <BeforeWrongAnswerBox QuestionsNumber={item[0]} Type3BeforeWrongAnswer={Type3BeforeWrongAnswer} setType3BeforeWrongAnswer={setType3BeforeWrongAnswer} WrongAnswer={WrongAnswer} />


            </>
        )
    }

    // Type 4: Drag and drop with text/image/video
    else if (item[1].type === 4) {

        return (
            <>
                <Center key={index} marginTop={"1%"}>
                    <Box className={"Type4DragAndDropDiv"} borderWidth={"1.2px"} borderRadius={"2xl"} ref={Type4HeightOfTheDivRef} backgroundColor={"gray.50"}>
                        <Box className={"img-overlay-wrap"}>
                            {
                                item[1].questionMedia &&
                                <>
                                    <Box>
                                        <Image onLoad={ImageWHSet} style={{ visibility: Type4DisplayImage }} ref={Type4HeightOfTheImageRef} className='Type4DragAndDropMedia' src={item[1].questionMedia} alt="Question Media" />
                                        {
                                            Type4WidhtOfTheImage > 0 && Type4HeightOfTheImage > 0 &&

                                            <svg viewBox={`0 0 ${Type4WidhtOfTheImage} ${Type4HeightOfTheImage}`}>
                                                {
                                                    item[1].svgCoordinates.map((item, index, arr) => {
                                                        return (
                                                            <g id={item.id} className={"Type4DragAndDropRectBox"}>
                                                                <rect rx={10} key={item.id} x={item.x} y={item.y} width={item.width} height={item.height} fill="#c3c9c9" style={{ strokeWidth: "0.5", stroke: "#535954" }} />
                                                                {Type4EndGameDiplay === "visible" ?
                                                                    <text style={{ userSelect: "none" }} fontSize={"x-small"} x={(parseFloat(item.x) + parseFloat(item.width) / 2).toString() + "%"} y={(parseFloat(item.y) + parseFloat(item.height) / 2).toString() + "%"} dominant-baseline="middle" text-anchor="middle">
                                                                        Drop Here!
                                                                    </text>
                                                                    :
                                                                    <text style={{ userSelect: "none" }} fontWeight={600} fontSize={"md"} x={(parseFloat(item.x) + parseFloat(item.width) / 2).toString() + "%"} y={(parseFloat(item.y) + parseFloat(item.height) / 1.9).toString() + "%"} dominant-baseline="middle" text-anchor="middle">
                                                                        {item.answerText}
                                                                    </text>
                                                                }
                                                            </g>
                                                        )
                                                    })
                                                }
                                            </svg>
                                        }
                                        {Type4WidhtOfTheImage > 0 && Type4HeightOfTheImage > 0 && Type4DisplayImage === "hidden" && <> {setType4DisplayImage("visible")} </>}
                                    </Box>
                                </>
                            }
                            <Box display={"flex"} flexDirection={"row"}>
                                {
                                    item[1].svgCoordinates[0] &&
                                    <motion.div
                                        ref={Type4Button1Ref}
                                        id='AnswerButton1'
                                        className="modal"
                                        initial={Type4DragCordsOfAnswer1}
                                        drag={true}
                                        onDragEnd={handleDragEndButton1}
                                        dragMomentum={false}
                                        style={{ width: "fit-content", height: "fit-content", opacity: Type4EndGameDiplay === "hidden" ? 0.3 : 1 }}
                                    >
                                        <Button id='Type4AnswerBoxButton' style={{ backgroundColor: Type4AnswerButtonColor1 }}>
                                            {item[1].svgCoordinates[0].answerText}
                                        </Button>
                                    </motion.div>
                                }
                                {
                                    item[1].svgCoordinates[1] &&
                                    <motion.div
                                        ref={Type4Button2Ref}
                                        id='AnswerButton1'
                                        className="modal"
                                        initial={Type4DragCordsOfAnswer2}
                                        drag={true}
                                        onDragEnd={handleDragEndButton2}
                                        dragMomentum={false}
                                        style={{ width: "fit-content", height: "fit-content", opacity: Type4EndGameDiplay === "hidden" ? 0.3 : 1, marginLeft: "4%" }}
                                    >
                                        <Button id='Type4AnswerBoxButton' style={{ backgroundColor: Type4AnswerButtonColor2 }}>
                                            {item[1].svgCoordinates[1].answerText}
                                        </Button>
                                    </motion.div>
                                }
                                {
                                    item[1].svgCoordinates[2] &&
                                    <motion.div
                                        ref={Type4Button3Ref}
                                        id='AnswerButton1'
                                        className="modal"
                                        initial={Type4DragCordsOfAnswer3}
                                        drag={true}
                                        onDragEnd={handleDragEndButton3}
                                        dragMomentum={false}
                                        style={{ width: "fit-content", height: "fit-content", opacity: Type4EndGameDiplay === "hidden" ? 0.3 : 1, marginLeft: "4%" }}
                                    >
                                        <Button id='Type4AnswerBoxButton' style={{ backgroundColor: Type4AnswerButtonColor3 }}>
                                            {item[1].svgCoordinates[2].answerText}
                                        </Button>
                                    </motion.div>
                                }
                                {
                                    item[1].svgCoordinates[3] &&
                                    <motion.div
                                        ref={Type4Button4Ref}
                                        id='AnswerButton1'
                                        className="modal"
                                        initial={Type4DragCordsOfAnswer4}
                                        drag={true}
                                        onDragEnd={handleDragEndButton4}
                                        dragMomentum={false}
                                        style={{ width: "fit-content", height: "fit-content", opacity: Type4EndGameDiplay === "hidden" ? 0.3 : 1, marginLeft: "4%" }}
                                    >
                                        <Button id='Type4AnswerBoxButton' style={{ backgroundColor: Type4AnswerButtonColor4 }}>
                                            {item[1].svgCoordinates[3].answerText}
                                        </Button>
                                    </motion.div>
                                }
                                {
                                    item[1].svgCoordinates[4] &&
                                    <motion.div
                                        ref={Type4Button5Ref}
                                        id='AnswerButton1'
                                        className="modal"
                                        initial={Type4DragCordsOfAnswer5}
                                        drag={true}
                                        onDragEnd={handleDragEndButton5}
                                        dragMomentum={false}
                                        style={{ width: "fit-content", height: "fit-content", opacity: Type4EndGameDiplay === "hidden" ? 0.3 : 1, marginLeft: "4%" }}
                                    >
                                        <Button id='Type4AnswerBoxButton' style={{ backgroundColor: Type4AnswerButtonColor5 }}>
                                            {item[1].svgCoordinates[4].answerText}
                                        </Button>
                                    </motion.div>
                                }
                            </Box>


                            {/* <Text>X: {Type4DragCordsOfAnswer1.x} and Y: {Type4DragCordsOfAnswer1.y}</Text>
                            <Text>Div height: {Type4HeightOfTheBox} and {Type4WidhtOfTheBox} Image: {Type4HeightOfTheImage} widht: {Type4WidhtOfTheImage}</Text> */}


                            {
                                !Type3BeforeCorrectAnswer && !Type3BeforeWrongAnswer &&
                                <Button marginTop={"2%"} float={"right"} marginBottom={"2%"} colorScheme='green' onClick={() => Type4CheckAnswers()}> Valmis </Button>
                            }


                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Vastaa kaikkiin kysymyksin!</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text> Vedä kaikki vastaukset niille sovituille paikoille! </Text>

                                        {/* 
                                        {Type4AnswerButtonConnect1 && <Text>{item[1].svgCoordinates[0].answerText} on yhdistetty?: {Type4AnswerButtonConnect1 === 0 ? <Text colorScheme='red'> Kyllä </Text> : <Text colorScheme='red'> Ei </Text> }</Text>}
                                         */}
                                        <Text> : {Type4AnswerButtonConnect1}</Text>
                                        <Text>Connect2: {Type4AnswerButtonConnect2}</Text>
                                        <Text>Connect3: {Type4AnswerButtonConnect3}</Text>
                                        <Text>Connect4: {Type4AnswerButtonConnect4}</Text>
                                        <Text>Connect5: {Type4AnswerButtonConnect5}</Text>

                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                            <BeforeCorrectAnswerBox QuestionsNumber={item[0]} Type3BeforeCorrectAnswer={Type3BeforeCorrectAnswer} setType3BeforeCorrectAnswer={setType3BeforeCorrectAnswer} CorrectAnswer={CorrectAnswer} />
                            <BeforeWrongAnswerBox QuestionsNumber={item[0]} Type3BeforeWrongAnswer={Type3BeforeWrongAnswer} setType3BeforeWrongAnswer={setType3BeforeWrongAnswer} WrongAnswer={WrongAnswer} />

                        </Box>

                    </Box>
                </Center>



            </>
        )
    }

    // Type 5: Images and Videos
    else if (item[1].type === 5) {

        return (
            <div key={index}>
                <p className='Type1Question'> {item[1].questionText}</p>
                <p> Type: {item[1].type} </p>

                <div className='Type1AnswerOptionsDiv'>

                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[0].correctOption && CorrectAnswer() }}> 1: {item[1].answerOptions[0].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[1].correctOption && CorrectAnswer() }}> 2: {item[1].answerOptions[1].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[2].correctOption && CorrectAnswer() }}> 3: {item[1].answerOptions[2].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[3].correctOption && CorrectAnswer() }}> 4: {item[1].answerOptions[3].answerText}</Button>

                </div>


            </div>
        )
    }

    // Type 7: Flashcards
    else if (item[1].type === 7) {

        return (
            <>
                <Center key={index} marginTop="1%">
                    <Box>

                        <motion.div
                            className="card__wrapper"
                            onClick={() => {
                                setType7ShowFlashcard(!Type7ShowFlashcard);
                                setType7ShowNext(true);
                            }}
                        >
                            <motion.div
                                transition={{ duration: 0.8 }}
                                initial={false}
                                animate={{ rotateY: Type7ShowFlashcard ? 180 : 0 }}
                                className="card"
                            >
                                <Box position={"relative"}>
                                    <Center>
                                        <Text textAlign={"center"} fontSize={"lg"} fontWeight={"600"}>{item[1].flashcardFront}</Text>
                                    </Center>

                                    <Center>
                                        {
                                            item[1].flashcardFrontMedia &&
                                            <Image marginTop={"2%"} src={item[1].flashcardFrontMedia} alt="flashcard front media" />
                                        }

                                    </Center>
                                    <Text display={"grid"} placeItems={"center"} position={"fixed"} bottom={"5%"} left={"50%"} transform={"translateX(-50%)"}>Click to Flip the Card <RiArrowGoBackLine /></Text>
                                </Box>

                            </motion.div>
                            <motion.div
                                transition={{ duration: 0.8 }}
                                initial={false}
                                animate={{ rotateY: Type7ShowFlashcard ? 0 : -180 }}
                                className="card"
                            >
                                <Box>
                                    <Center>
                                        <Text textAlign={"center"} fontSize={"lg"} fontWeight={"600"}>{item[1].flashcardBack}</Text>
                                    </Center>
                                </Box>

                                <Center>
                                    {
                                        item[1].flashcardBackMedia &&
                                        <Image marginTop={"2%"} src={item[1].flashcardBackMedia} alt="flashcard back media" />
                                    }
                                </Center>

                                <Text display={"grid"} placeItems={"center"} position={"fixed"} bottom={"5%"} left={"50%"} transform={"translateX(-50%)"}>Click to Flip the Card <RiArrowGoBackLine /></Text>

                            </motion.div>
                        </motion.div>

                    </Box>

                </Center>

                {
                    Type7ShowNext &&
                    <Center marginTop={"1%"}>
                        <Box>
                            <Center>

                                <Text> Uskotko että osasit?</Text>
                            </Center>
                            <Center>
                                <Button colorScheme='green' onClick={() => CorrectAnswer(item[0])}>Kyllä</Button>
                                <Button onClick={() => WrongAnswer(item[0])}>Ei</Button>
                            </Center>

                        </Box>
                    </Center>
                }


            </>
        )
    }

    // Type 8: Matching
    else if (item[1].type === 8) {

        return (
            <div key={index}>
                <p className='Type1Question'> {item[1].questionText}</p>
                <div className='Type1AnswerOptionsDiv'>

                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[0].correctOption && CorrectAnswer() }}> 1: {item[1].answerOptions[0].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[1].correctOption && CorrectAnswer() }}> 2: {item[1].answerOptions[1].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[2].correctOption && CorrectAnswer() }}> 3: {item[1].answerOptions[2].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[3].correctOption && CorrectAnswer() }}> 4: {item[1].answerOptions[3].answerText}</Button>

                </div>

            </div>
        )
    }

    // Type 9: Fill in the blank with multiple right answers (1/3 --> 2/3 --> 3/3)
    else if (item[1].type === 9) {

        return (
            <div key={index}>
                <p className='Type1Question'> {item[1].questionText}</p>

                <div className='Type1AnswerOptionsDiv'>

                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[0].correctOption && CorrectAnswer() }}> 1: {item[1].answerOptions[0].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[1].correctOption && CorrectAnswer() }}> 2: {item[1].answerOptions[1].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[2].correctOption && CorrectAnswer() }}> 3: {item[1].answerOptions[2].answerText}</Button>
                    <Button className='Type1AnswerOptionsDivButton' onClick={() => { item[1].answerOptions[3].correctOption && CorrectAnswer() }}> 4: {item[1].answerOptions[3].answerText}</Button>

                </div>


            </div>
        )
    }


    else {

        return (
            <>
                <div>Havaittu onglema - Ota yhteyttä sivustoon</div>
            </>
        )
    }

}

export default Quiz