import React, { useState, useContext } from 'react'
import "../../styles/TopicPageStartBox.css"


import {
  Button,
  Text,
  ButtonGroup,
  Card,
  CardBody,
  Box,
  Badge,
  Center,
  Image,
  Stack

} from '@chakra-ui/react'

import { MdArrowBackIosNew } from "react-icons/md";

import { useNavigate } from 'react-router-dom'

// Database Reference
import { db } from '../../firebaseConfig';
import { collection, addDoc, getDoc, doc, setDoc, FieldValue, updateDoc, increment } from "@firebase/firestore";
import { getAuth } from 'firebase/auth';

import { GlobalContext } from '../../components/GlobalContext/GlobalContext';

import Quiz from '../../components/Quiz';




function BiologiaPage1() {

  const navigate = useNavigate();

  const { signOutContext, QuestionArrayFunction } = useContext(GlobalContext);

  const [isGameOn, setIsGameOn] = useState(false);
  const [QuestionArray, setQuestionArray] = useState(null)
  const [questionNumber, setQuestionNumber] = useState(0)
  const [showEndOfGame, setShowEndOfGame] = useState(false);
  const [QuestionDataArray, setQuestionDataArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const StartTheGame = async () => {

    QuestionArrayFunction(1).then(async result => {
      if (result) {
        var tempQuestionDataArray = []
        setQuestionArray(result);

        var p = new Promise((resolve, reject) => {
          result.forEach(async (r, index, array) => {
            await getDoc(doc(db, "Cards", "Biologia", "Biologia1", "Card" + String(r))).then((result) => {
              tempQuestionDataArray.push(result.data())
              console.log("result.data()", result.data())
            })
            if (index === array.length - 1) resolve();
          });
        })
      }
      p.then(async () => {
        var tempCominationArray1 = []
        for (let i = 0; i < result.length; i++) {
          var tempCominationArray2 = []
          tempCominationArray2.push(String(result[i]))
          tempCominationArray2.push(tempQuestionDataArray[i])
          tempCominationArray1.push(tempCominationArray2)
        }
        setQuestionDataArray(tempCominationArray1) // with [[8, {}], [2, {}]]
        setIsGameOn(true);
        console.log(Object.entries(Object.assign({}, tempQuestionDataArray)), "aslkjlskadlsjda")

      });
    })



  }

  function WrongAnswer(QuestionsNumber) {

    setQuestionNumber(questionNumber + 1)

    if (Number(QuestionArray[QuestionArray.length - 1]) === Number(QuestionsNumber)) {
      setShowEndOfGame(true)
      console.log("SET SHOW END OF GAME TRUE")
    }

  }

  async function CorrectAnswer(QuestionsNumber) {

    const auth = getAuth();
    const userAuth = auth.currentUser;

    setQuestionNumber(questionNumber + 1)

    console.log("Last usablearray", QuestionDataArray[questionNumber], "and questions number: ", QuestionsNumber)
    try {

      await setDoc(doc(db, "Users", userAuth.uid), {

        Biologia1QuestionArray: { [QuestionsNumber]: increment(1) },
        Biologia1TotalScore: increment(1)

      }, { merge: true })


    }
    catch (e) {
      console.log("got error: ", e)
    }
    if (Number(QuestionArray[QuestionArray.length - 1]) === Number(QuestionsNumber)) {
      setShowEndOfGame(true)
      console.log("SET RUE SHOW END OF GMAE")
    }
  }

  return (
    <>

      {
        isGameOn ? (
          <>

            <p style={{ fontSize: "small" }}> Biologia/Alku</p>

            <Button marginTop={"0.5%"} colorScheme='blue' leftIcon={<MdArrowBackIosNew />} onClick={() => navigate("/Biologia")}>Takaisin</Button>
            {
              QuestionDataArray && QuestionArray && QuestionDataArray.length > 0 && QuestionArray.length > 0 && QuestionDataArray.map((Question, index) => {
                //console.log(index, "index")
                //console.log(UsableExistingArray, "UsableExistingArray")

                if (index === questionNumber) {
                  return (
                    <>
                      <Quiz key={index} item={Question} index={index} CorrectAnswer={CorrectAnswer} WrongAnswer={WrongAnswer} LastQuestion={true} />
                    </>
                  )
                }
                return null

              })

            }

            {

              showEndOfGame &&
              <>
                <p> Onnea! </p>
                <Button colorScheme='blue' onClick={() => setIsGameOn(false)}> Palaa takaisin alkuun ja aloita uudestaan</Button>
              </>
            }
          </>
        ) : (

          <>
            <Button marginTop={"0.5%"} marginBottom={"0.5%"} colorScheme='blue' leftIcon={<MdArrowBackIosNew />} onClick={() => navigate("/Biologia")}>Takaisin</Button>

            <Center>
              <Box>
                <Center display={"flex"} justifyContent={"center"} flexDirection={"column"} backgroundColor={"gray.50"} borderWidth={"1.2px"} borderRadius={"2xl"} width={["2xl", "2xl", "2xl", "2xl"]}>
                  <Text fontWeight={600} fontSize={"2xl"} padding={"0.5%"} marginTop={"1%"}>Alku</Text>

                  <Box className="TopicPageBody" margin={"2%"}>
                    <Text textAlign={"center"}> voluptatum quidem quos quas nesciunt. Quisquam, quae. Quisquam
                      voluptas, quod, quia, voluptates quae voluptatibus quibusdam
                      voluptas, quod, quia, voluptates quae voluptatibus quibusdam  </Text>
                  </Box>
                  {
                    isLoading ?
                      (
                        <Button size={"2xl"} fontSize={"2xl"} isLoading marginBottom={"2%"}>Loading</Button>
                      )
                      :
                      (
                        <a href="#" className="btn" onClick={() => { setIsLoading(true); StartTheGame(); }}>Aloita</a>
                      )
                  }
                </Center>
              </Box>
            </Center >
          </>
        )
      }
    </>
  )
}

export default BiologiaPage1