import React, { createContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';


// Database Reference
import { db } from "../../firebaseConfig";
import { collection, addDoc, getDoc, doc, setDoc } from "@firebase/firestore";



export const GlobalContext = createContext(null);

export const GlobalContextProvider = ({ children }) => {


    const auth = getAuth();

    const navigate = useNavigate();


    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isPremium, setIsPremium] = useState(true);
    const [user, setUser] = useState({});

    const [clearLocalStorageCounterForReRender, setClearLocalStorageCounterForReRender] = useState(0);

    //Homepage GUEST
    const [gameIsOn, setGameIsOn] = useState(false);
    const [subject, setSubject] = useState("");
    const [topic, setTopic] = useState("");
    //Homepage GUEST



    const signOutContext = () => {
        setIsSignedIn(false);
        setIsPremium(false);
        signOut(auth).then(() => {

            console.log("Sign-out successful");
            localStorage.removeItem("user");
            setUser({});


        }).catch((error) => {

            console.log(error);

        });
        navigate("/");

    }

    const QuestionArrayFunction = async (SubjectNumber) => {

        var Array0Points = []
        var Array1Points = []
        var Array2Points = []
        var Array3Points = []
        var Array4Points = []
        var Array5Points = []

        const ObjectWithCardKeys = {};

        for (let i = 1; i < 11; i++) {
            Object.assign(ObjectWithCardKeys, { [i]: 0 })
        }

        const auth = getAuth();
        const userAuth = auth.currentUser;

        try {

            const docRef = doc(db, "Users", userAuth.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data().uid);

                if (docSnap.data().uid === JSON.parse(localStorage.getItem("user"))["uid"] && docSnap.data().CodecLite === true) {

                    console.log("CodecLite is true - Checked by Database")

                    // Here the aftermath after security CodecLite check

                    try {

                        if (SubjectNumber === 1) {
                            (
                                Object.entries(docSnap.data().Biologia1QuestionArray).map((item, index) => {
                                    Object.assign(ObjectWithCardKeys, { [item[0]]: item[1] })
                                })
                            )
                        }
                        else if (SubjectNumber === 2) {
                            (
                                Object.entries(docSnap.data().Biologia2QuestionArray).map((item, index) => {
                                    Object.assign(ObjectWithCardKeys, { [item[0]]: item[1] })
                                })
                            )
                        }

                        else if (SubjectNumber === 3) {
                            (
                                Object.entries(docSnap.data().Biologia3QuestionArray).map((item, index) => {
                                    Object.assign(ObjectWithCardKeys, { [item[0]]: item[1] })
                                })
                            )
                        }
                        else if (SubjectNumber === 4) {
                            (
                                Object.entries(docSnap.data().Biologia4QuestionArray).map((item, index) => {
                                    Object.assign(ObjectWithCardKeys, { [item[0]]: item[1] })
                                })
                            )
                        }
                        else if (SubjectNumber === 5) {
                            (
                                Object.entries(docSnap.data().Biologia5QuestionArray).map((item, index) => {
                                    Object.assign(ObjectWithCardKeys, { [item[0]]: item[1] })
                                })
                            )
                        }

                        console.log("Objectcard after: ", ObjectWithCardKeys)

                        Object.entries(ObjectWithCardKeys).map((item) => {
                            if (item[1] <= 5) {
                                if (item[1] === 0) {
                                    Array0Points.push(Number(item[0]));
                                } else if (item[1] === 1) {
                                    Array1Points.push(Number(item[0]));
                                } else if (item[1] === 2) {
                                    Array2Points.push(Number(item[0]));
                                } else if (item[1] === 3) {
                                    Array3Points.push(Number(item[0]));
                                } else if (item[1] === 4) {
                                    Array4Points.push(Number(item[0]));
                                } else if (item[1] === 5) {
                                    Array5Points.push(Number(item[0]));
                                }
                            }
                            return null
                        })

                        // Randomize the order of question numbers
                        Array0Points.sort(() => (Math.random() > .5) ? 1 : -1)
                        Array1Points.sort(() => (Math.random() > .5) ? 1 : -1)
                        Array2Points.sort(() => (Math.random() > .5) ? 1 : -1)
                        Array3Points.sort(() => (Math.random() > .5) ? 1 : -1)
                        Array4Points.sort(() => (Math.random() > .5) ? 1 : -1)
                        Array5Points.sort(() => (Math.random() > .5) ? 1 : -1)

                        console.log("Array0Points: ", Array0Points, Array0Points.length)
                        console.log("Array1Points: ", Array1Points, Array1Points.length)
                        console.log("Array2Points: ", Array2Points, Array2Points.length)
                        console.log("Array3Points: ", Array3Points, Array3Points.length)
                        console.log("Array4Points: ", Array4Points, Array4Points.length)
                        console.log("Array5Points: ", Array5Points, Array5Points.length)

                        var Array0PointsCopy = [...Array0Points]
                        var Array1PointsCopy = [...Array1Points]
                        var Array2PointsCopy = [...Array2Points]
                        var Array3PointsCopy = [...Array3Points]
                        var Array4PointsCopy = [...Array4Points]
                        var Array5PointsCopy = [...Array5Points]

                        console.log("----------------------------")

                        var howManyToAsk0 = 3 // 2--> 3
                        var howManyToAsk1 = 2
                        var howManyToAsk2 = 2
                        var howManyToAsk3 = 1 // 2 --> 1
                        var howManyToAsk4 = 1
                        var howManyToAsk5 = 1

                        var countOfPlusQuestionsWeNeed0 = 0
                        var countOfPlusQuestionsWeNeed1 = 0
                        var countOfPlusQuestionsWeNeed2 = 0
                        var countOfPlusQuestionsWeNeed3 = 0
                        var countOfPlusQuestionsWeNeed4 = 0
                        var countOfPlusQuestionsWeNeed5 = 0



                        // Check if array5 gives same amount as hwmanytoask


                        if (Array5PointsCopy.pop() === undefined) {
                            howManyToAsk5 -= 1;
                            countOfPlusQuestionsWeNeed5 += 1;
                        }
                        if (Array4PointsCopy.pop() === undefined) {
                            howManyToAsk4 -= 1;
                            countOfPlusQuestionsWeNeed4 += 1;
                        }


                        var i3 = 0;
                        while (i3 < 1) { // 2 --> 1
                            if (Array3PointsCopy.pop() === undefined) {
                                howManyToAsk3 -= 1
                                countOfPlusQuestionsWeNeed3 += 1;
                            }
                            i3 += 1;
                        }

                        var i2 = 0;
                        while (i2 < 2) {
                            if (Array2PointsCopy.pop() === undefined) {
                                howManyToAsk2 -= 1
                                countOfPlusQuestionsWeNeed2 += 1;
                            }
                            i2 += 1;
                        }

                        var i1 = 0;
                        while (i1 < 2) {
                            if (Array1PointsCopy.pop() === undefined) {
                                howManyToAsk1 -= 1
                                countOfPlusQuestionsWeNeed1 += 1;
                            }
                            i1 += 1;
                        }

                        var i0 = 0;
                        while (i0 < 3) { // 2 --> 3
                            if (Array0PointsCopy.pop() === undefined) {
                                howManyToAsk0 -= 1
                                countOfPlusQuestionsWeNeed0 += 1;
                            }
                            i0 += 1;
                        }

                        while (countOfPlusQuestionsWeNeed5 > 0) {
                            if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 } // Add random that first array is 4/3
                            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
                            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
                            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
                            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
                            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
                            countOfPlusQuestionsWeNeed5 -= 1
                        }
                        while (countOfPlusQuestionsWeNeed4 > 0) {
                            if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
                            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
                            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
                            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
                            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
                            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
                            countOfPlusQuestionsWeNeed4 -= 1
                        }
                        while (countOfPlusQuestionsWeNeed3 > 0) {
                            if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
                            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
                            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
                            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
                            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
                            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
                            countOfPlusQuestionsWeNeed3 -= 1
                        }
                        while (countOfPlusQuestionsWeNeed2 > 0) {
                            if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
                            else if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
                            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
                            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
                            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
                            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
                            countOfPlusQuestionsWeNeed2 -= 1
                        }
                        while (countOfPlusQuestionsWeNeed1 > 0) {
                            if (Array0PointsCopy.length > 0) { Array0PointsCopy.pop(); howManyToAsk0 += 1 }
                            else if (Array1PointsCopy.length > 0) { Array1PointsCopy.pop(); howManyToAsk1 += 1 }
                            else if (Array2PointsCopy.length > 0) { Array2PointsCopy.pop(); howManyToAsk2 += 1 }
                            else if (Array3PointsCopy.length > 0) { Array3PointsCopy.pop(); howManyToAsk3 += 1 }
                            else if (Array4PointsCopy.length > 0) { Array4PointsCopy.pop(); howManyToAsk4 += 1 }
                            else if (Array5PointsCopy.length > 0) { Array5PointsCopy.pop(); howManyToAsk5 += 1 }
                            countOfPlusQuestionsWeNeed1 -= 1
                        }

                        console.log("howManyToAsk0: ", howManyToAsk0)
                        console.log("howManyToAsk1: ", howManyToAsk1)
                        console.log("howManyToAsk2: ", howManyToAsk2)
                        console.log("howManyToAsk3: ", howManyToAsk3)
                        console.log("howManyToAsk4: ", howManyToAsk4)
                        console.log("howManyToAsk5: ", howManyToAsk5)

                        var tempArray = []

                        while (howManyToAsk5 > 0) {
                            tempArray.push(Array5Points.pop())
                            howManyToAsk5 -= 1
                        }
                        while (howManyToAsk4 > 0) {
                            tempArray.push(Array4Points.pop())
                            howManyToAsk4 -= 1
                        }
                        while (howManyToAsk3 > 0) {
                            tempArray.push(Array3Points.pop())
                            howManyToAsk3 -= 1
                        }
                        while (howManyToAsk2 > 0) {
                            tempArray.push(Array2Points.pop())
                            howManyToAsk2 -= 1
                        }
                        while (howManyToAsk1 > 0) {
                            tempArray.push(Array1Points.pop())
                            howManyToAsk1 -= 1
                        }
                        while (howManyToAsk0 > 0) {
                            tempArray.push(Array0Points.pop())
                            howManyToAsk0 -= 1
                        }

                        console.log("TEMPARRAY eli loppuarray: ", tempArray)

                        return tempArray


                        /* setUsableExistingArray(tempArray)
                        setPlayTheGame(true) */



                        /* const docRef2 = doc(db, "Cards", "Biologia", "Biologia1", "Card5");
                        const docSnap2 = await getDoc(docRef2);
            
                        if (docSnap2.exists()) {
                          console.log("Document data:", docSnap2.data());
            
                          setIsGameOn(true)
            
                        } else {
                          // doc.data() will be undefined in this case
                          console.log("No such document!");
                        } */
                    }

                    catch (error) {
                        console.log(error);
                    }

                }
                else {
                    console.log("CodecLite is false -- Trying to scam!")
                    signOutContext();
                }

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
        catch (error) {
            console.log(error);
        }

    }



    return (
        <GlobalContext.Provider value={{ isSignedIn, setIsSignedIn, isPremium, setIsPremium, user, setUser, setClearLocalStorageCounterForReRender, signOutContext, subject, setSubject, topic, setTopic, gameIsOn, setGameIsOn, QuestionArrayFunction }}>
            {children}
        </GlobalContext.Provider>
    );
}
