import React, { useState, useContext } from 'react';

import {
  Button,
  Text,
  ButtonGroup,
  Card,
  CardBody,
  Box,
  Badge,
  Center,
  Image,
  Stack

} from '@chakra-ui/react'
import { MdArrowBackIosNew } from "react-icons/md";
import { FaDna } from "react-icons/fa";
import { BiAtom } from "react-icons/bi";
import { SlChemistry } from "react-icons/sl";


import "../styles/SubjectDeckBox.css";
import "../styles/SubjectTopicBox.css";

import GuestGame from "../components/GuestGame";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ProgressProvider from "../components/ProgressProvider";

import { GlobalContext } from '../components/GlobalContext/GlobalContext';




// Database Reference
// import { db } from '../firebaseConfig';
// import { collection, addDoc } from "@firebase/firestore";




function HomePageGuest() {

  const { clearLocalStorageCounterForReRender, topic, setTopic, subject, setSubject, gameIsOn, setGameIsOn } = useContext(GlobalContext);

  function CircularProgBar({ topicText }) {

    var TotalNumberOfQuestions = localStorage.getItem(topicText) ? Object.keys(JSON.parse(localStorage.getItem(topicText))).length : 0;
    var TotalNumberOfQuestionsPOINTS = TotalNumberOfQuestions * 5;
    var NumberOfCorrectAnswersPOINTS = 0;

    localStorage.getItem(topicText) && Object.keys(JSON.parse(localStorage.getItem(topicText))).map((item) => NumberOfCorrectAnswersPOINTS += (JSON.parse(localStorage.getItem(topicText))[item]));
    return (
      <div style={{ width: 100, height: 100 }}>

        <ProgressProvider valueStart={10} valueEnd={(NumberOfCorrectAnswersPOINTS) / TotalNumberOfQuestionsPOINTS * 100 > 0 ? ((NumberOfCorrectAnswersPOINTS) / TotalNumberOfQuestionsPOINTS * 100).toFixed(1) : 0}>
          {value => <CircularProgressbar styles={buildStyles({

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            // Text size
            textSize: '20px',
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 1,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `rgba(62, 152, 199)`,
            textColor: '#2a6270',
            trailColor: '#c5e6df',
          })}
            value={value} text={`${value}%`} />}
        </ProgressProvider>

      </div>
    )
  }


  return (
    <>

      {
        clearLocalStorageCounterForReRender === 1000 && (
          <>
          </>
        )
      }

      <div className="home-page">

        {
          gameIsOn ? (
            <GuestGame setGameIsOnProp={setGameIsOn} Subject={subject} Topic={topic} />
          ) : (
            <>
              {
                subject === "Biologia" ? (

                  <>
                    <Button marginTop={"0.5%"} marginBottom={"0.5%"} padding={"6"} colorScheme='blue' leftIcon={<MdArrowBackIosNew />} onClick={() => { setSubject(""); setTopic("") }}>Takaisin</Button>

                    <Center className='subject-topic-box-center1'>
                      <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                        <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                          <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Alku</Text>
                          {/* Oikea reuna: Ilmais kortteja: (16 kpl - ei ehkä ilmoiteta korttejan määrää) i symbol - Ostamalla premium saat yhteensä 1300 korttia */}
                          <Button margin={"1%"} colorScheme='blue' onClick={() => { setGameIsOn(true); setTopic("BG1") }}> Play </Button>
                          <CircularProgBar topicText={"BG1"} />
                        </Center>
                      </Box>

                      <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                        <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                          <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Ekologia</Text>
                          <Button margin={"1%"} colorScheme='blue' onClick={() => { setGameIsOn(true); setTopic("BG2") }}> Play </Button>
                          <CircularProgBar topicText={"BG2"} />
                        </Center>
                      </Box>

                      <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                        <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                          <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Solu</Text>
                          <Button margin={"1%"} colorScheme='blue' onClick={() => { setGameIsOn(true); setTopic("BG3") }}> Play </Button>
                          <CircularProgBar topicText={"BG3"} />
                        </Center>
                      </Box>

                      <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                        <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                          <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Ihmis Biologia</Text>
                          <Button margin={"1%"} colorScheme='blue' onClick={() => { setGameIsOn(true); setTopic("BG4") }}> Play </Button>
                          <CircularProgBar topicText={"BG4"} />
                        </Center>
                      </Box>

                      <Box width={["90%", "80%", "35%", "35%"]} borderRadius={"lg"} className='subject-topic-box-center-box1'>
                        <Center bgColor={"gray.50"} borderRadius={"lg"} borderWidth={"1.2px"} padding={"3%"} className='subject-topic-box-center2'>
                          <Text fontWeight={600} fontSize={"2xl"} padding={"1%"}>Geenitekniikka</Text>
                          <Button margin={"1%"} colorScheme='blue' onClick={() => { setGameIsOn(true); setTopic("BG5") }}> Play </Button>
                          <CircularProgBar topicText={"BG5"} />
                        </Center>
                      </Box>
                    </Center>
                  </>

                ) : subject === "Fysiikka" ? (
                  <>

                    <Button onClick={() => { setSubject(""); setTopic("") }}>Takaisin</Button>

                    <div className='subject-topic-box'>
                      <h4>fy1</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY1") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy2</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY2") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy3</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY3") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy4</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY4") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy5</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY5") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy6</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY6") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>fy7</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("FY7") }}> Play </Button>
                    </div>

                  </>
                ) : subject === "Kemia" ? (

                  <>
                    <Button onClick={() => { setSubject(""); setTopic("") }}>Takaisin</Button>

                    <div className='subject-topic-box'>
                      <h4>ke1</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("KE1") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>ke2</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("KE2") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>ke3</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("KE3") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>ke4</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("KE4") }}> Play </Button>
                    </div>

                    <div className='subject-topic-box'>
                      <h4>ke5</h4>
                      <Button onClick={() => { setGameIsOn(true); setTopic("KE5") }}> Play </Button>
                    </div>

                  </>
                ) : (

                  <>
                    <Center paddingTop={"1%"}>
                      <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
                        <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
                          <Stack direction='row' spacing={4} align='center' position={"relative"}>
                            <Box
                              mt='1'
                              fontWeight='bold'
                              as='h4'
                              lineHeight='tight'
                              noOfLines={1}
                              fontSize={"xx-large"}
                              marginBottom={2}
                            >
                              Biologia
                            </Box>
                            <FaDna color='green' size={30} style={{ marginLeft: "15px" }} />

                            <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} onClick={() => { setSubject("Biologia") }}>Valitse</Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Center>

                    <Center paddingTop={"1%"}>
                      <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
                        <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
                          <Stack direction='row' spacing={4} align='center' position={"relative"}>
                            <Box
                              mt='1'
                              fontWeight='bold'
                              as='h4'
                              lineHeight='tight'
                              noOfLines={1}
                              fontSize={"xx-large"}
                              marginBottom={2}
                            >
                              Fysiikka
                            </Box>
                            <BiAtom color='lightblue' size={30} style={{ marginLeft: "15px" }} />
                            <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} onClick={() => { setSubject("Fysiikka") }}>Valitse</Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Center>

                    <Center paddingTop={"1%"}>
                      <Box width={"xl"} borderRadius='lg' bgColor={"gray.50"}>
                        <Box borderWidth='1.2px' p="4" borderRadius={"lg"}>
                          <Stack direction='row' spacing={4} align='center' position={"relative"}>
                            <Box
                              mt='1'
                              fontWeight='bold'
                              as='h4'
                              lineHeight='tight'
                              noOfLines={1}
                              fontSize={"xx-large"}
                              marginBottom={2}
                            >
                              Kemia
                            </Box>
                            <SlChemistry color='orange' size={30} style={{ marginLeft: "15px" }} />
                            <Button colorScheme="blue" position={"absolute"} p={8} right={"0"} onClick={() => { setSubject("Kemia") }}>Valitse</Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Center>
                  </>
                )
              }
            </>
          )
        }
      </div>
    </>
  )
}


export default HomePageGuest