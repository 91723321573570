import React, { useState } from 'react';
import { GuestBiologia } from "./Data/GuestQuestions/GuestBiologiaQuestions";
import { Button, ButtonGroup } from '@chakra-ui/react'

import QuizGame from './QuizGame';


function GuestGame({ setGameIsOnProp, Subject, Topic }) {

  return (
    <div className="game-box">
      
      <p style={{fontSize: "small"}}> {Subject}/{Topic}</p>

      <Button marginTop={"0.5%"} marginBottom={"0.5%"} colorScheme='blue' onClick={() => setGameIsOnProp(false)}>Takaisin</Button>

      {

        Subject === "Biologia" ? (
          <QuizGame data={GuestBiologia} topic={Topic} />

        ) : (
          <h1>Ei ole vielä saatavilla :d </h1>
        )

      }

    </div>
  )
}

export default GuestGame